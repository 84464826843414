import {
  LocalizationProvider as LocalizationProviderMUI,
} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import useLocale from '../hooks/localization/useLocale';

function ProviderMUILocalization({children}) {

  const {locale} = useLocale();

  return <LocalizationProviderMUI
      dateAdapter={AdapterDayjs}
      adapterLocale={locale}
  >
    {children}
  </LocalizationProviderMUI>;
}



export default ProviderMUILocalization;
