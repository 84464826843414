import { lazy } from 'react';
import {Route} from 'react-router-dom';
const PagePaymentStatus = lazy(() => import('../pages/PaymentStatus/PagePaymentStatus'));
const PagePaymentStatusRecurring = lazy(() => import('../pages/PaymentStatus/PagePaymentStatusRecurring'));

const RoutesPayments = (
    <>
      <Route path="/payments/status"
             element={<PagePaymentStatus/>}/>

      <Route path="/payments/recurring-booking/status"
             element={<PagePaymentStatusRecurring/>}/>

    </>
);

export default RoutesPayments;
