import axios from 'axios';

export function customersWalletListSelf_GET(
    page, rowsPerPage, cbSuccess, cbFail) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
          `customers/wallet/list/self?page=${page}&limit=${rowsPerPage}`,{
            headers: {
              'x-clubos-club': '',
              'x-clubos-club-info': ''
            }
          });
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}

export function customersWalletSelf_GET(clubId, cbSuccess, cbFail) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
          `customers/wallet/self?clubId=${clubId}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}

export function customersWalletSingle_GET(transactionId, cbSuccess, cbFail) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
          `customers/wallet/single/${transactionId}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}

export function customersOffersList_GET(
    clubId, page, rowsPerPage, cbSuccess, cbFail) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
          `customers/offers/list?clubId=${clubId}&page=${page}&limit=${rowsPerPage}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}

export function customersOffersSingle_GET(offerId, cbSuccess, cbFail) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`customers/offers/single/${offerId}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}

export function customersOffersPurchase_POST(
    offerId, redirectUrl, cbSuccess, cbFail) {

  const body = {
    redirectUrl,
  };

  return async (dispatch) => {
    try {
      const response = await axios.post(`customers/offers/purchase/${offerId}`,
          body);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}

export function customersOffersRetryPayment_POST(
    orderId, redirectUrl, cbSuccess, cbFail) {

  const body = {
    redirectUrl,
  };

  return async (dispatch) => {
    try {
      const response = await axios.post(`customers/offers/retry-payment/${orderId}`,
          body);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}

export function customersOffersPaymentStatus_GET(orderId, cbSuccess, cbFail) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`customers/offers/payment-status/${orderId}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}

export function customersWalletHistorySelf_GET(
    clubId, page, rowsPerPage, cbSuccess, cbFail) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
          `customers/wallet/history/self?clubId=${clubId}&page=${page}&limit=${rowsPerPage}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}

export function checkoutWalletTopUp_POST(
    payload, cbSuccess, cbFail) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/wallet/topup/checkout`,payload);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}

export function customersWalletTopupPaymentStatus_GET(topupId, cbSuccess, cbFail) {
  return async (dispatch) => {
    try {
      const response = await axios.get(`customers/offers/payment-status/${topupId}`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
}




