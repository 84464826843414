import {Box} from '@mui/material';
import {
  LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT,
  LAYOUT_MAIN__HEADER__TOP__HEIGHT,
  LAYOUT_MAIN__PUBLIC_HEADER__HEIGHT,
  LAYOUT_MAIN__MOBILE_HEADER__HEIGHT,
  LAYOUT_MAIN__PAGE_PADDING_BOTTOM,
} from '../../constants/ui';
import {useEffect, useRef} from 'react';
import SiteFooter from '../../components/_Global/Site/SiteFooter';
import ContainerFullWidth
  from '../../components/_Global/Containers/ContainerFullWidth';
import useIsMobile from '../../hooks/ui/useIsMobile';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';

function LayoutMainPage({children}) {

  const pageRef = useRef(null);
  const isMobile = useIsMobile();
  const isAuthenticated = useIsAuthenticated();

  let pageHeight;

  if (!isMobile && isAuthenticated) pageHeight = `calc(100vh - (${LAYOUT_MAIN__HEADER__TOP__HEIGHT} + ${LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT}))`;
  if (!isMobile && !isAuthenticated) pageHeight = `calc(100vh - (${LAYOUT_MAIN__PUBLIC_HEADER__HEIGHT}))`;
  if (isMobile) pageHeight = `calc(100vh - ${LAYOUT_MAIN__MOBILE_HEADER__HEIGHT})`;

  // Used to un-focus autocomplete suggestions
  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.addEventListener('scroll', () => {
        document.activeElement.blur();
      });
    }
  }, [pageRef.current]);

  return (
      <Box
          component={'main'}
          sx={{
            display: 'flex',
            height: pageHeight,
          }}
      >
        <Box
            ref={pageRef}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              overflowY: 'scroll',
              overflowX: 'auto',
              paddingTop: 0,
            }}>
          <ContainerFullWidth>
            {children}
          </ContainerFullWidth>
          <Box sx={{minHeight: LAYOUT_MAIN__PAGE_PADDING_BOTTOM}}></Box>
          <SiteFooter/>
        </Box>
      </Box>
  );
}

export default LayoutMainPage;
