export const statusLoading = (value) => ({
  type: value,
});

export const statusError = (value, payload) => ({
  type: value,
  payload,
});

export const statusSuccess = (value, payload) => ({
  type: value,
  payload,
});
