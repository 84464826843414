import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {Controller, useForm} from 'react-hook-form';
import LayoutOnboarding from '../../layouts/LayoutOnboarding/LayoutOnboarding';
import {validate} from 'email-validator';
import {useTranslate} from '@tolgee/react';
import {useState} from 'react';
import useBreakpoint from '../../hooks/ui/useBreakpoint';
import ForgotPasswordSubmitSuccessAlert
  from '../../components/Onboarding/ForgotPassword/ForgotPasswordSubmitSuccessAlert';
import {ERR_NETWORK_ERROR} from '../../constants/errors';

const PageForgotPassword = () => {

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslate();
  const navigateTo = useNavigate();
  const {isSmDown} = useBreakpoint();

  const {
    handleSubmit,
    control,
    getValues,
    formState: {errors, isSubmitting},
  } = useForm();

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const handleForgotPasswordSubmit = () => {
    handleSubmit(
        async ({email}) => {
          const url = 'customers/forget-password';
          const emailLowerCase = email?.toLowerCase()
          try {
            const response = await axios.post(url, {email: emailLowerCase});
            enqueueSnackbar(t('global.alerts.passwordReset.sent'),
                {variant: 'success'});
            setSubmitSuccess(true);
          } catch (e) {
            const err = e.response?.data?.data || ERR_NETWORK_ERROR;
            enqueueSnackbar(t(err), {variant: 'error'});
            setSubmitSuccess(false);
          }
        },
        () => {
          enqueueSnackbar(t('alerts.globalErrorMessage'), {variant: 'error'});
          setSubmitSuccess(false);
        },
    )();
  };

  const handleEnterKeySubmit = (e) => {
    e.key === 'Enter' && handleForgotPasswordSubmit();
  };

  return (
      <LayoutOnboarding isLoading={isSubmitting}>
        <Box mb={3}>
          {
              !submitSuccess &&
              <Typography variant="h5" mb={1}>
                {t('authFlow.forgotPassword.heading')}
              </Typography>
          }
          {
              !submitSuccess &&
              <Typography variant="body2" color={'text.secondary'}>
                {t('authFlow.forgotPassword.subHeading')}
              </Typography>
          }
        </Box>
        <Stack>
          {
              !submitSuccess &&
              <Controller
                  name={'email'}
                  control={control}
                  defaultValue={''}
                  rules={{
                    required: {
                      value: true,
                      message: t('authFlow.fields.errorEmail'),
                    },
                    validate: {
                      invalid: (value) => {
                        return validate(value) ||
                            t('validation.emailValidation');
                      },
                    },
                  }}
                  render={({field}) => {
                    return (
                        <TextField
                            autoFocus
                            fullWidth
                            required
                            size="medium"
                            type="email"
                            name="email"
                            label={t('authFlow.fields.labelEmail')}
                            placeholder="John@email.com"
                            error={!!errors['email']}
                            helperText={!!errors['email'] &&
                                errors['email'].message}
                            onKeyDown={handleEnterKeySubmit}
                            {...field}
                        />
                    );
                  }}
              />
          }
          {
              submitSuccess &&
              <ForgotPasswordSubmitSuccessAlert email={getValues('email')}/>
          }
        </Stack>

        <Stack direction={'row'} columnGap={1} mt={3} flexWrap={'wrap'} rowGap={1}>
          <Button
              size={'large'}
              variant="outlined"
              onClick={() => {
                navigateTo(-1);
              }}
              fullWidth={isSmDown}
          >
            {t('buttons.backButton')}
          </Button>
          {
              !submitSuccess &&
              <Button
                  size={'large'}
                  variant="contained"
                  onClick={handleForgotPasswordSubmit}
                  disabled={isSubmitting || submitSuccess || errors['email']}
                  fullWidth={isSmDown}
              >
                {t('buttons.linkButton')}
              </Button>
          }
        </Stack>
      </LayoutOnboarding>
  );
};

export default PageForgotPassword;
