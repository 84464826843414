import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import {TransitionFade, TransitionSlide} from '../../../vendor/mui/transitions';
import useIsMobile from '../../../hooks/ui/useIsMobile';
import {West} from '@mui/icons-material';
import useVisualViewportHeight from '../../../hooks/ui/useVisualViewportHeight';

function DialogBase({
                      title,
                      description,
                      titleLabelSlot,
                      contentSlot,
                      actionsSlot,
                      altActionsSlot,
                      isOpen,
                      isLoading,
                      isSubmitting,
                      dividers = true,
                      scroll = 'body',
                      maxWidth = 'sm',
                      onClose = () => {
                      },
                      onExited = () => {
                      },
                      onEnter = () => {
                      },
                      onEntered = () => {
                      },
                      transitionComponent: TransitionComponent = TransitionFade,
                    }) {

  const theme = useTheme();
  const isMobile = useIsMobile();

  // const visualViewportHeight = useVisualViewportHeight();

  if (isMobile) {
    maxWidth = null;
  }

  const desktopPaperStyles = {
    position: 'relative',
    borderRadius: '12px',
  };

  const defaultProps = {
    fullScreen: isMobile,
    fullWidth: true,
    maxWidth: maxWidth,
    disableRestoreFocus:true,
    open: isOpen,
    scroll: scroll,
    TransitionComponent: TransitionComponent,
    TransitionProps: {
      onExited: onExited,
      onEnter: onEnter,
      onEntered: onEntered,
    },
  };

  if (!isMobile) {
    return (
        <Dialog
            {...defaultProps}
            sx={{'.MuiPaper-root': {...desktopPaperStyles}}}
            onClose={onClose}
        >
          {
              isSubmitting &&
              <LinearProgress
                  sx={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    width: '100%',
                  }}
              />
          }
          <DialogTitle>
            <Stack>
              <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  mb={1}
              >
                <Typography variant={'h5'}>
                  {!isLoading && title}
                  {isLoading && <Skeleton width={'100px'}/>}
                </Typography>
                {
                    !isLoading && titleLabelSlot &&
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                      <Typography>
                        {titleLabelSlot}
                      </Typography>
                    </Box>
                }
                {
                    isLoading &&
                    <Skeleton variant={'circular'} width={'40px'}
                              height={'40px'}/>
                }
              </Stack>
              {
                  description &&
                  <Typography variant={'body2'}>
                    {!isLoading && description}
                    {isLoading && <Skeleton width={'80px'}/>}
                  </Typography>
              }
            </Stack>
          </DialogTitle>
          {
              isLoading && dividers &&
              <Skeleton height={'2px'} width={'100%'}/>
          }
          <DialogContent
              dividers={!isLoading && dividers}
              sx={{overflow: 'hidden'}}>

            {contentSlot}
          </DialogContent>
          {
              isLoading && dividers &&
              <Skeleton height={'2px'} width={'100%'}/>
          }
          {
              actionsSlot &&
              <DialogActions>
                {
                    !isLoading && actionsSlot
                }
                {
                    isLoading &&
                    <Skeleton width={'180px'} height={'60px'}/>
                }
              </DialogActions>
          }
          {
              altActionsSlot &&
              <Stack alignItems={'center'} mb={3} px={3}>
                {altActionsSlot}
              </Stack>
          }
        </Dialog>
    );
  }

  if (isMobile) {
    return (
        <Dialog
            {...defaultProps}
            TransitionComponent={TransitionSlide}
            fullScreen
            sx={{
              // height: visualViewportHeight,
              '.MuiPaper-root[role=dialog]': {
                borderRadius: '0',
              },
              // transition: '.1s linear height',
            }}

        >
          <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
          }}>
            <Box
                sx={{
                  position: 'relative',
                  flexGrow: 1,
                  width: '100%',
                  backgroundColor: theme.palette.grey[100],
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  px: 2,
                  py: 1.5,
                }}>
              <Stack>
                {
                    !isLoading && title &&
                    <Stack direction={'row'} columnGap={1} alignItems={'center'}
                           flexWrap={'wrap'}>
                      <Typography variant={'h6'}>{title}</Typography>
                      {titleLabelSlot && titleLabelSlot}
                    </Stack>
                }
                {
                    !isLoading && description &&
                    <Typography variant={'body2'}>{description}</Typography>
                }
                {
                    isLoading &&
                    <Typography variant={'h6'}>
                      <Skeleton width={'200px'}/>
                    </Typography>
                }
                {
                    isLoading &&
                    <Typography variant={'body2'}>
                      <Skeleton width={'100px'}/>
                    </Typography>
                }
              </Stack>
              <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  sx={{ml: 'auto'}}
                  onClick={onClose}
              >
                <West/>
              </IconButton>
              {
                  isSubmitting &&
                  <LinearProgress
                      sx={{
                        position: 'absolute',
                        left: '0',
                        bottom: '0',
                        width: '100%',
                      }}
                  />
              }
            </Box>
            <Box
                sx={{
                  overflowX: 'hidden',
                  overflowY: 'scroll',
                  height: '100%',
                  width: '100%',
                  py: 1,
                  px: 3,
                }}
            >
              {contentSlot}

            </Box>
            {
                isLoading && <Skeleton height={'2px'} width={'100%'}/>
            }
            {
                actionsSlot &&
                <Stack
                    direction={'row'}
                    justifyContent={'flex-end'}
                    spacing={1}
                    sx={{
                      position: 'relative',
                      p: 2,
                      ...(!isLoading) && {
                        borderTop: `1px solid ${theme.palette.divider}`,
                      },
                    }}
                >
                  {!isLoading && actionsSlot}
                  {isLoading && <Skeleton height={'60px'} width={'160px'}/>}
                </Stack>
            }
            {
                altActionsSlot &&
                <Stack
                    alignItems={'center'}
                    mb={3}
                    px={1}
                >
                  {altActionsSlot}
                </Stack>
            }
          </Box>
        </Dialog>
    );
  }

}

export default DialogBase;