import {
  CHANGE_PLAYER_PASSWORD_FAILURE,
  CHANGE_PLAYER_PASSWORD_LOADING,
  CHANGE_PLAYER_PASSWORD_SUCCESS,
  EDIT_CUSTOMER_FAILURE,
  EDIT_CUSTOMER_LOADING,
  EDIT_CUSTOMER_SUCCESS,
  GET_PLAYER_BYID_FAILURE,
  GET_PLAYER_BYID_LOADING,
  GET_PLAYER_BYID_SUCCESS,
  VALIDATE_PLAYER_PASSWORD_FAILURE,
  VALIDATE_PLAYER_PASSWORD_LOADING,
  VALIDATE_PLAYER_PASSWORD_SUCCESS,
} from '../constants';
import {statusError, statusLoading, statusSuccess} from './status';
import axios from 'axios';

export const customersSelf_GET = (cbSuccess,cbFail) => {
  return async () => {
    try {
      const response = await axios.get(`v2/customers/self`);
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
    }
  };
};

export const playerByID_GET = (customerId,cbCallback) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_PLAYER_BYID_LOADING));
      const response = await axios.get(`customers/${customerId}`);
      dispatch(statusSuccess(GET_PLAYER_BYID_SUCCESS, response.data));
      cbCallback(response);
    } catch (e) {
      console.log(e);
      cbCallback(e);
      dispatch(statusError(GET_PLAYER_BYID_FAILURE, e));
    }
  };
};



export const customersUpdateSelf_PATCH = (body,cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(EDIT_CUSTOMER_LOADING));
      const response = await axios.patch(`customers/update/self`, body);
      dispatch(statusSuccess(EDIT_CUSTOMER_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      console.log(e);
      cbFail(e);
      dispatch(statusError(EDIT_CUSTOMER_FAILURE, e));
    }
  };
};


export const playerValidatePasword_POST = (body,cbCallback) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(VALIDATE_PLAYER_PASSWORD_LOADING));
      const response = await axios.post(`customers/validate-password/self`, body);
      dispatch(statusSuccess(VALIDATE_PLAYER_PASSWORD_SUCCESS, response.data));
      cbCallback(response);
    } catch (e) {
      console.log(e);
      cbCallback(e);
      dispatch(statusError(VALIDATE_PLAYER_PASSWORD_FAILURE, e));
    }
  };
};

export const playerChangePasword_POST = (body,cbCallback) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CHANGE_PLAYER_PASSWORD_LOADING));
      const response = await axios.post(`customers/update-password/self`, body);
      dispatch(statusSuccess(CHANGE_PLAYER_PASSWORD_SUCCESS, response.data));
      cbCallback(response);
    } catch (e) {
      console.log(e);
      cbCallback(e);
      dispatch(statusError(CHANGE_PLAYER_PASSWORD_FAILURE, e));
    }
  };
};
