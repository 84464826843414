import {
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import useBreakpoint from '../../../hooks/ui/useBreakpoint';

function DialogSection({
                         label,
                         icon: Icon,
                         isDisabled = false,
                         isLoading = false,
                         isLastChild = false,
                         paddingY = '20px',
                         children,
                         isHideIcon= false
                       }) {
  const theme = useTheme();

  const {isSmDown} = useBreakpoint();

  return <>
    <Box sx={{
      cursor: (!isLoading && isDisabled) ? 'not-allowed' : '',
      width: '100%',
    }}>
      <Box
          sx={{
            pointerEvents: (!isLoading && isDisabled) ? 'none' : '',
            width: '100%',
          }}
      >
        <Stack
            direction={'row'}
            spacing={'9px'}
            width={'100%'}
            sx={{paddingY: paddingY}}
        >
          {
              !isSmDown && isLoading && !!Icon &&
              <Skeleton variant={'circular'} width={'23px'} height={'20px'}/>
          }
          {
              !isSmDown && !isLoading && !!Icon &&
              <Icon sx={{
                fill: theme.palette.icon.primary,
                opacity: isDisabled ? 0.4 : 1,
                position: 'relative',
                top: '2px',
                visibility: isHideIcon ? 'hidden' : 'initial'
              }}/>
          }
          <Stack
              width={'100%'}
              sx={{opacity: isDisabled ? 0.4 : 1}}
          >
            {
                isLoading &&
                <Typography variant={'overline'}>
                  <Skeleton width={'60px'} height={'20px'}/>
                </Typography>
            }
            {
                !isLoading &&
                <Stack direction={'row'} alignItems={'center'} spacing={.75} sx={{
                ...(isSmDown) && {
                  mb:.5
                }
                }}>
                  {
                      isSmDown &&
                      <Icon sx={{
                        fill: theme.palette.icon.primary,
                        opacity: isDisabled ? 0.4 : 1,
                        position: 'relative',
                        top:"-2px",
                        fontSize: 20
                      }}/>
                  }
                  <Typography
                      variant={'overline'}
                      sx={{
                        lineHeight: '31.92px',
                        mb: '2px',
                      }}
                  >
                    {label}
                  </Typography>
                </Stack>
            }
            {children}
          </Stack>
        </Stack>
        {
            !isLoading && !isLastChild && <Divider/>
        }
        {
            isLoading && !isLastChild &&
            <Skeleton width={'100%'} height={'2px'}/>
        }
      </Box>
    </Box>
  </>;
}

export default DialogSection;
