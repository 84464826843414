import { lazy } from 'react';
import {Route} from 'react-router-dom';
const PageMyClubs = lazy(() => import('../pages/MyClubs/PageMyClubs'));
const RoutesMyClubs = (
    <>
      <Route path={`/my-clubs`} element={<PageMyClubs/>}/>
    </>
);

export default RoutesMyClubs;
