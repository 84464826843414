import { fireGAEvent } from "./google-analytics"

export const purchasePackagesEvents={

    'selectPackage':()=>{
        return fireGAEvent('pl_packages_select_a_package_btn_clicked','Purchase package','Package Selected')
    },
    'proceedToPayment':()=>{
        return fireGAEvent('pl_packages_proceed_to_payment_btn_clicked','Purchase package','Proceed to payment for purchase package')
    },
    'close':()=>{
        return fireGAEvent('pl_packages_close_btn_clicked','Purchase package','Purchase package dialog closed')
    },
    'purchaseSuccess':()=>{
        return fireGAEvent('pl_packages_purchased_success','Purchase package','Successfully package purchased')
    },
    'purchaseFailed':()=>{
        return fireGAEvent('pl_packages_purchase_failed','Purchase package','Package purchase failed')
    }
}


export const customerEvents={

    'eventEntryClicked':()=>{
        return fireGAEvent('pl_events_entry_clicked','Event Registration','Event Item clicked');
    },
    'registerSuccess':()=>{
        return fireGAEvent('pl_events_registration_success','Event Registration','Event Registration success');
    },
    'registerFailed':()=>{
        return fireGAEvent('pl_events_registration_failed','Event Registration','Event Registration Failed');
    },
    'useWallet':()=>{
        return fireGAEvent('pl_events_use_wallet_btn_clicked','Use Wallet','User clicked on use wallet switch for event');
    },
    'removeGuest':()=>{
        return fireGAEvent('pl_events_remove_guest_btn_clicked','Remove Guest','Guest Removed');
    },
    'addGuest':()=>{
        return fireGAEvent('pl_events_enters_player_email','Add Guest','Add guest clicked');
    },
 
}

export const bookingEvents={

    'clickOnTimeslot':()=>{
        return fireGAEvent('pl_bookings_timeslot_btn_clicked','Booking','User select an available time slot');
    },
    'selectCourtForBooking':()=>{
        return fireGAEvent('pl_bookings_court_clicked','Booking','User select available court for booking');
    },
    'removePlayer':()=>{
        return fireGAEvent('pl_bookings_remove_player_btn_clicked','Booking','Remove player btn clicked');
    },
    'paymentTypeSingle':()=>{
        return fireGAEvent('pl_bookings_change_payment_single','Payment Type','Payment type single');
    },
    'paymentTypeSplit':()=>{
        return fireGAEvent('pl_bookings_change_payment_split','Payment Type','Payment type split');
    },
    'useWalletFromDetails':()=>{
        return fireGAEvent('pl_bookings_use_wallet_btn_clicked','Use Wallet','User clicked on use wallet switch from booking details'); 
    },
    'useWalletFromAdd':()=>{
        return fireGAEvent('pl_bookings_use_wallet_btn_clicked','Use Wallet','User clicked on use wallet switch while add booking'); 
    },
    'extraChange':()=>{
        return fireGAEvent('pl_bookings_add_extras','Add Extra','User add extra'); 
    },
    'addPlayer':()=>{
        return fireGAEvent('pl_bookings_add_player_btn_clicked','Add Player','User added player');
    },
    'addPlayerFieldClicked':()=>{
        return fireGAEvent('pl_bookings_enters_player_email','Add Player','User clicked add player field');
    },
    'bookingSuccess':()=>{
        return fireGAEvent('booking_success', 'Booking', 'Booking Create Success');
    },
    'bookingConfirm':()=>{
        return fireGAEvent('pl_bookings_confirmed', 'Booking', 'Booking Confirmed');
    },
    'bookingFailed':()=>{
        return fireGAEvent('pl_bookings_failed', 'Booking', 'Booking Failed');
    },
    'bookingPaymentFailed':()=>{
        return fireGAEvent('pl_bookings_unpaid', 'Booking', 'Booking Payment Failed');
    },
}


export const filtersEvents={

    'dateChange':()=>{
        return fireGAEvent('pl_filters_change_date_btn_clicked','Change Date','User Change Date');
    },
    'timeChange':()=>{
        return fireGAEvent('pl_filters_change_time_btn_clicked','Change Time','User Change Time');
    },
    'durationChange':()=>{
        return fireGAEvent('pl_filters_change_duration_btn_clicked','Duration','User Change Duration');
    },
    'filterOpen':()=>{
        return fireGAEvent('pl_filters_court_filter_open_btn_clicked','Filter Open','Filter Button Clicked');
    },
    'applyFilter':()=>{
        return fireGAEvent('pl_filters_court_filter_btn_clicked','Filter Applied','User Applied filter');
    },
    'clearFilter':()=>{
        return fireGAEvent('pl_filters_court_filter_clear_btn_clicked','Filter Clear','User Clear filter');
    },
 
}

export const signUpEvents={

    'openSignupPopup':()=>{
        return fireGAEvent('pl_create_account_open','Sign Up screen','Sign-up screen open');
    },
    'signUpFailed':()=>{
        return fireGAEvent('pl_create_account_failure', 'Onboarding', 'Sign up failed');
    }

}


export const loginEvents={

    'openLoginPopup':()=>{
        return fireGAEvent('pl_login_open','Login Screen','Login pop-up open');
    }

}

export const forgotPasswordEvents={

    'openPopup':()=>{
        return fireGAEvent('pl_forgot_password_open','Forgot password','Forgot password screen open');
    },
    'success':()=>{
        return fireGAEvent('pl_forgot_password_success','Forgot password Email','Forgot password email successfully sent');
    },
    'failure':()=>{
        return fireGAEvent('pl_forgot_password_failure','Forgot password Email','Forgot password email sent failed');
    }

}

export const offersEvents={

    'paymentSuccess':()=>{
        return fireGAEvent('pl_offers_offer_purchased','Offers','User Comes to offer purchased page');
    },
    'offerSlideRight':()=>{
        return fireGAEvent('pl_offers_slide_right_btn_clicked','Offers','Offer Slide Right');
    },
    'offerSlideLeft':()=>{
        return fireGAEvent('pl_offers_slide_left_btn_clicked','Offers','Offer Slide Left');
    },
    'paymentFailed':()=>{
        return fireGAEvent('pl_offers_payment_failed','Offers','Payment failed');
    }
}

export const bookingsTabEvents={

    'clickedOnUpcomingBooking':()=>{
           
        fireGAEvent('pl_bookings_tab_upcoming_entry_clicked','Bookings Tab','User Clicks on upcoming booking');
        fireGAEvent('pl_bookings_tab_upcoming_entry_detail_sheet_open','Bookings Tab','Upcoming booking details screen open');
        
    },
   
    'clickedOnCancelBooking':()=>{
           
        fireGAEvent('pl_bookings_tab_upcoming_cancel_booking','Bookings Tab','User Clicks on cancelled booking');
        
    },
    
    'clickedOnRecurringBooking':()=>{
        fireGAEvent('pl_bookings_tab_recurring_entry_clicked','Bookings Tab','User Clicks on recurring booking');
        fireGAEvent('pl_bookings_tab_recurring_entry_detail_sheet_open','Bookings Tab','Recurring booking details screen open');
    },
    
    'clickedOnPreviousBooking':()=>{
        fireGAEvent('pl_bookings_tab_previous_entry_clicked','Bookings Tab','User Clicks on previous booking');
        fireGAEvent('pl_bookings_tab_previous_entry_detail_sheet_open','Bookings Tab','Previous booking details screen open');
    },
    
    'confirmBookingCancellation':()=>{
        return fireGAEvent('pl_bookings_tab_upcoming_confirm_cancellation','Bookings Tab','User confirms booking cancellation');
    },
}


export const walletTabEvents={

    'clickOnClub':()=>{
        fireGAEvent('pl_wallet_club_item_btn_clicked','Wallet Tab','User click on club');
        fireGAEvent('pl_wallet_club_open','Wallet Tab','User comes to club wallet');
    },
    'clickOnOffer':()=>{
        return fireGAEvent('pl_wallet_club_offer_btn_clicked','Wallet Tab','User clicks on Offer')
    },
    'paymentSuccess':()=>{
        return fireGAEvent('pl_wallet_club_offer_payment_success','Wallet Tab','Payment Success')
    },
    'paymentFailed':()=>{
        return fireGAEvent('pl_wallet_club_offer_payment_failed','Wallet Tab','{Payment Failed')
    },
    'clickOnRetryBtn':()=>{
        return fireGAEvent('pl_wallet_retry_payment_btn_clicked','Wallet Tab','User clicks on retry btn')
    },
    'clickOnTransaction':()=>{
        return fireGAEvent('pl_wallet_club_transaction_entry_clicked','Wallet Tab','User clicks on transaction row')
    },
    'clickOnWalletTransaction':()=>{

        // fireGAEvent('pl_wallet_club_transaction_open','Wallet Tab','User clicks on Wallet transaction')
        fireGAEvent('pl_wallet_club_transaction_detail_page_open','Wallet Tab','Wallet transaction detail open')
    
    }

}

export const customerEventsTabs={

    'clickedOnActiveEvent':()=>{
        return fireGAEvent('pl_events_active_entry_clicked','Events Tab','User clicked on active event row');
    },
    'viewActiveRegistrationDetails':()=>{
        return fireGAEvent('pl_events_active_view_registration_button','Events Tab','View active evnet registration details');
    },

    'clickedOnExpriedEvent':()=>{
        return fireGAEvent('pl_events_past_entry_clicked','Events Tab','User clicked on past event row');
    },
    'viewExpiredRegistrationDetails':()=>{
        return fireGAEvent('pl_events_past_view_registration_button','Events Tab','View expired event registration details');
    }
}

export const clubSelected={
    'selectClub':()=>{
        return fireGAEvent('pl_my_clubs_book_now_btn_clicked','My Clubs','User clicks on Book Now')
    }
}

export const settingsDropdown={
    'clickOnDropdown':()=>{
        return fireGAEvent('pl_settings_dropdown','Settings','Clicked on dropdown')
    }
}

export const profileEvents={
    'editBtnClick':()=>{
        return fireGAEvent('pl_profile_edit_btn_clicked','Profile','Edit btn clicked')
    },
    'editSaveBtnClick':()=>{
        return fireGAEvent('pl_profile_edit_save_btn_clicked','Profile','Save btn clicked')
    },
    'editSuccess':()=>{
        return fireGAEvent('pl_profile_edit_save_success','Profile','Edit Success')
    },
    'editFailed':()=>{
        return fireGAEvent('pl_profile_edit_save_failed','Profile','Edit Failed')
    }
}

export const changePasswordEvents={
    'unlockSuccess':()=>{
        return fireGAEvent('pl_change_pass_unlock_successful','Change Password','Unlock successful')
    },
    'passwordChangeSuccess':()=>{
        return fireGAEvent('pl_change_pass_success','Change Password','Change Password success')
    },
    'passwordChangeFailed':()=>{
        return fireGAEvent('pl_change_pass_failed','Change Password','Change Password Failed')
    }
}

export const packageEvents={
    'clickOnActivePkg':()=>{
        fireGAEvent('pl_packages_item_btn_clicked','Packages','User click on active package row');
        fireGAEvent('pl_packages_item_open','Packages','User view active package details');
    },
    'clickOnExpiredPkg':()=>{
        fireGAEvent('pl_packages_expired_item_btn_clicked','Packages','User click on active package row');
        fireGAEvent('pl_packages_expired_item_open','Packages','User view expired package details');
    },
} 

export const headerEvents={

    'clickedOnLanguage':()=>{
        return fireGAEvent('pl_language_btn_clicked','Header','Language Changed');
    }
}