import {useState} from 'react';
import axios from 'axios';
import {USER_LOGIN} from '../../vendor/redux/constants';
import {useSnackbar} from 'notistack';
import {useTranslate} from '@tolgee/react';
import {useDispatch} from 'react-redux';
import useSetLanguage from '../localization/useSetLanguage';
import {ERR_NETWORK_ERROR} from '../../constants/errors';
import { fireGAEvent } from '../../utils/google-analytics';

function useLogin(onLoginSuccess = () => {}) {

  const {enqueueSnackbar} = useSnackbar();
  const dispatch = useDispatch();
  const {t} = useTranslate();

  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const setLanguage = useSetLanguage();

  async function handleLogin(email, password) {
    try {
      const APIUrl = 'customers/login';
      const response = await axios.post(APIUrl, {email, password});
      const user = response.data.data;
      localStorage.setItem('user', JSON.stringify(user));
      dispatch({type: USER_LOGIN, payload: user});
      //set app language to user preference
      const lang = user?.userObj?.language || 'nl'
      setLanguage(lang);
      //alert success
      enqueueSnackbar(t('global.alerts.loginSuccess'), {
        variant: 'success',
      });
      fireGAEvent('pl_logon_success', 'User Login', 'Login Sucess');
      onLoginSuccess();
    } catch (e) {

      const err = e?.response?.data?.data || ERR_NETWORK_ERROR;
      enqueueSnackbar(t(err), {variant: 'error'});

      fireGAEvent('pl_logon_failure', 'User Login', 'Login Failed');
      setEmailError({
        type: 'account error',
        message: '',
      });

      setPasswordError({
        type: 'password error',
        message: '',
      });
    }
  }

  return {
    handleLogin,
    emailError,
    passwordError,
  };

}

export default useLogin;