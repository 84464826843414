import {useTolgee} from '@tolgee/react';
import {Box, IconButton, ListItemIcon, Menu, MenuItem} from '@mui/material';
import useLocale from '../../../hooks/localization/useLocale';
import FlagEnUS from '../../../assets/flags/FlagEnUS';
import FlagNl from '../../../assets/flags/FlagNl';
import {useEffect, useState} from 'react';
import useSetLanguage from '../../../hooks/localization/useSetLanguage';
import { headerEvents } from '../../../utils/analyticsEvents';

function SiteLanguageSwitcher() {


  const {locale} = useLocale();
  const setLanguage = useSetLanguage(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function handleClickMenuItem(key) {
    setLanguage(key);
    handleCloseMenu();
  }

  const flags = {
    en: FlagEnUS,
    nl: FlagNl,
    // es: FlagES,
    // fr: FlagFR,
    // it: FlagIT,
  };

  let SelectedFlag = flags[locale];

  const locales = [
    {
      key: 'en',
      label: 'English (US)',
    },
    {
      key: 'nl',
      label: 'Nederlands (NL)',
    },
    // {
    //   key: 'es',
    //   label: 'Español (ES)',
    // },
    // {
    //   key: 'fr',
    //   label: 'Français (FR)',
    // },
    // {
    //   key: 'it',
    //   label: 'Italiano (IT)',
    // },
  ];

  useEffect(()=>{

    if(!!open){
      headerEvents['clickedOnLanguage']();
    }
  },[open])

  return (
      <Box>
        <IconButton onClick={handleClickOpenMenu} sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          svg: {
            width: '26px',
            height: '26px',
          },
        }}>
          <SelectedFlag/>
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
        >
          {
            locales.map((item, index) => {
              return <ListItem
                  key={index}
                  item={item}
                  flags={flags}
                  onClick={handleClickMenuItem}
              />;
            })
          }
        </Menu>

      </Box>
  );
}

function ListItem({item, flags, onClick}) {
  const Flag = flags[item.key];
  return (
      <MenuItem
          value={item.key}
          onClick={() => {
            onClick(item.key);
          }}
      >
        <ListItemIcon sx={{
          mr: .5,
          svg: {
            width: '28px',
            height: '28px',
          },
        }}><Flag/></ListItemIcon>
        {item.label}
      </MenuItem>
  );
}

export default SiteLanguageSwitcher;


