function IconSocialFacebook(props){
return (
    <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M14.6666 8.50016C14.6666 4.81827 11.6818 1.8335 7.99991 1.8335C4.31802 1.8335 1.33325 4.81827 1.33325 8.50016C1.33325 12.1245 4.22548 15.0734 7.82784 15.1646C7.43941 15.1547 7.0593 15.1114 6.69008 15.0379V9.96173H5.60282V8.1492H6.69008V7.06116C6.69008 5.5825 7.30389 4.70335 9.04806 4.70335H10.5001V6.51602H9.59245C8.91348 6.51602 8.86857 6.76918 8.86857 7.24189L8.86613 8.14906H10.5103L10.3179 9.96142H8.86613V15.1109C8.62446 15.1422 8.3813 15.1604 8.13773 15.1654C11.756 15.0921 14.6666 12.136 14.6666 8.50016Z" fill="url(#paint0_linear_6758_206609)"/>
      <defs>
        <linearGradient id="paint0_linear_6758_206609" x1="7.99991" y1="1.71202" x2="7.99991" y2="15.7362" gradientUnits="userSpaceOnUse">
          <stop stopColor="#00B2FF"/>
          <stop offset="1" stopColor="#006AFF"/>
        </linearGradient>
      </defs>
    </svg>

)
}

export default IconSocialFacebook;