import { useEffect, useState } from "react"

const useViewMobileBanner = props => {


    const view = localStorage.getItem('viewMobileBanner')
    const [viewMobileBanner, setViewMobileBanner] = useState(false)

    useEffect(() => {

        if (!!view) {

            setViewMobileBanner(false)

        } else {

            setViewMobileBanner(true)

        }

    }, [])

    return{
        viewMobileBanner,setViewMobileBanner
    }
}

export default useViewMobileBanner