import DialogBase from '../../components/_Global/Dialogs/DialogBase';
import {TransitionSlide} from '../../vendor/mui/transitions';
import {useTranslate} from '@tolgee/react';
import useRegister from '../../hooks/onboarding/useRegister';
import FormRegistration from '../../components/Onboarding/FormRegistration';
import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';

function DialogRegister({
                          isOpenDialog,
                          setIsOpenDialog,
                          setWillShowRegisterDialog,
                          onRegisterSuccess = () => {
                          },
                          onRegisterFailure=()=>{}
                        }) {

  const {t} = useTranslate();

  const {formMethods, handleSubmitForm, handleReset} = useRegister(
      () => {
        setIsOpenDialog(false);
        onRegisterSuccess();
      },
      ()=>{
        onRegisterFailure();
      }
  );

  const {formState: {isSubmitting}} = formMethods;

  return (
      <DialogBase
          isOpen={isOpenDialog}
          transitionComponent={TransitionSlide}
          onExited={() => {
            handleReset();
          }}
          onClose={() => {
            setIsOpenDialog(false);
            setWillShowRegisterDialog(false);
          }}
          title={t('authFlow.register.heading')}
          dividers={true}
          contentSlot={<Box mt={3} mb={3}>
            <FormRegistration formMethods={formMethods}/>
          </Box>}
          actionsSlot={<>
            <LoadingButton
                loading={isSubmitting}
                variant={'contained'}
                fullWidth
                size={'large'}
                onClick={handleSubmitForm}
            >
                <span>
                  {t('global.buttons.actions.signup')}
                </span>
            </LoadingButton>
          </>}
      />
  );

}

export default DialogRegister;