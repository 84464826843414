import { lazy } from 'react';
import {Route} from 'react-router-dom';
const PageSettings = lazy(() => import('../pages/Settings/PageSettings'));

const RoutesSettings = (
    <>
      <Route path={`/settings`} element={<PageSettings/>}/>
    </>
);

export default RoutesSettings;
