import {Controller, useFormContext} from 'react-hook-form';
import {useTranslate} from '@tolgee/react';
import {matchIsValidTel, MuiTelInput} from 'mui-tel-input';

function Phone() {
  const {control, formState: {errors}} = useFormContext();
  const {t} = useTranslate();

  return (
      <Controller
          name={'phone'}
          control={control}
          rules={{
            validate: matchIsValidTel,
          }}
          render={({field, fieldState}) => {

            const {phone: phoneErrors} = errors;
            let errorMessage;

            if (phoneErrors) {
              if (phoneErrors.type === 'exists') {
                errorMessage = t('register.fields.phone.error.exists');
              }
              if (phoneErrors.type === 'validate') {
                errorMessage = t('register.fields.phone.error');
              }
            }
            return <MuiTelInput
                {...field}
                fullWidth
                defaultCountry={'NL'}
                error={!!fieldState.invalid}
                helperText={errorMessage}
            />;
          }}
      />
  );

}

export default Phone;
