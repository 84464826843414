import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {useTranslate} from '@tolgee/react';
import {USER_LOGIN} from '../../vendor/redux/constants';
import {useDispatch} from 'react-redux';

function useRegister(onRegisterSuccess = () => {
},onRegisterFailure=()=>{}) {

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslate();
  const dispatch = useDispatch();

  const defaultValues = {
    phone: '',
    passwordConfirm: '',
    gender: '',
    dateOfBirth: null,
  };

  const formMethods = useForm({
    defaultValues: defaultValues,
  });

  const {register, reset, setError, handleSubmit, watch} = formMethods;

  register('isPasswordVisible', {
    value: false,
  });

  function handleSubmitForm() {
    return handleSubmit(handleSubmitOnValid, handleSubmitOnInvalid)();
  }

  function handleSubmitOnValid(data) {

    const body = {
      cellNumber: data.phone,
      email: data.email,
      dateOfBirth: data.dateOfBirth.format('YYYY-MM-DD'),
      gender: data.gender,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
    };

    return handleRegister(body);

  }

  function handleSubmitOnInvalid(e) {
    onRegisterFailure();
  }

  function handleReset() {
    reset(defaultValues);
  }

  async function handleRegister(body) {
    try {
      const APIUrl = 'v2/customers/signup';
      const response = await axios.post(APIUrl, body);
      console.log('Register Success', response);
      enqueueSnackbar(t('register.alerts.registerSuccess'), {
        variant: 'success',
      });
      //Auto sign user in after successfully registering
      const userData = response.data.data;
      localStorage.setItem('user', JSON.stringify(userData));
      dispatch({type: USER_LOGIN, payload: userData});
      enqueueSnackbar(t('authFlow.loginSuccess'), {variant: 'success'});
      //register success callback
      onRegisterSuccess();

    } catch (e) {
      console.log('Register Failed', e);
      const error = e.response?.data?.data;
      console.log('err', error);

      if (error === 'validation.error.emailExists') {
        enqueueSnackbar(t('register.alerts.emailExists'), {variant: 'error'});
        setError('email',
            {type: 'custom', message: t('register.error.emailExists')},
            {shouldFocus: true});
      }

      if (error === 'validation.error.phoneExists') {
        enqueueSnackbar(t('register.alerts.phoneExists'), {variant: 'error'});
        setError('phone',
            {type: 'exists', message: t('register.error.phoneExists')},
            {shouldFocus: true});
      }

      if (error === 'validation.error.firstName.containsNumbers') {

        enqueueSnackbar(t('register.alerts.firstName.containsNumbers'),
            {variant: 'error'});
        setError('firstName',
            {
              type: 'containsNumbers',
              message: t('register.error.firstName.ContainsNumbers'),
            },
            {shouldFocus: true});
      }

      if (error === 'validation.error.lastName.containsNumbers') {
        enqueueSnackbar(t('register.alerts.lastName.ContainsNumbers'),
            {variant: 'error'});
        setError('lastName',
            {
              type: 'containsNumbers',
              message: t('register.error.lastName.ContainsNumbers'),
            },
            {shouldFocus: true});

      }
     
    }
  }

  return {formMethods, handleSubmitForm, handleReset};

}

export default useRegister;