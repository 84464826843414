import {
  Alert,
  AlertTitle,
  Box,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import DialogSection from '../_Global/Dialogs/DialogSection';
import {
  AccessTime,
  AccountBalanceWalletOutlined,
  LocalOfferOutlined,
  Payment,
  PaymentsOutlined,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import {useTranslate} from '@tolgee/react';

import {LoadingButton} from '@mui/lab';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  customersOffersRetryPayment_POST,
} from '../../vendor/redux/actions/wallet';
import {useParams} from 'react-router-dom';
import dayjs from 'dayjs';
import useLocale from '../../hooks/localization/useLocale';
import {getLocalizedNum} from '../../utils/localization';
import {APP_DEFAULT_CURRENCY_SYMBOL} from '../../constants/localization';
import { walletTabEvents } from '../../utils/analyticsEvents';
import { offersEvents } from '../../utils/analyticsEvents';

function WalletOfferPurchaseDetails({details, orderId, onRetryPaymentRedirectUrl,}) {

  const paymentStatus = details?.paymentStatus;

  const url = window.location.pathname;

  const {t} = useTranslate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const {locale} = useLocale();
  dayjs.locale(locale);

  const [isSubmittingRetryPayment, setIsSubmittingRetryPayment] = useState(
      false);

  function handleRetryPayment() {

    walletTabEvents['clickOnRetryBtn']();
    
    setIsSubmittingRetryPayment(true);

    dispatch(customersOffersRetryPayment_POST(orderId, onRetryPaymentRedirectUrl, cbSuccess,
        cbFail));

    function cbSuccess(res) {
      console.log('customersOffersRetryPayment_POST Success', res);
      window.location.href = res.data.data.checkoutUrl;
    }

    function cbFail(e) {
      console.log('customersOffersRetryPayment_POST Fail', e);
      setIsSubmittingRetryPayment(false);
    }

  }

  useEffect(()=>{

    if(!url.includes('landingPage')){
      if(paymentStatus=='paid'){

        walletTabEvents['paymentSuccess']();
  
      }else if(paymentStatus=='unpaid'){
        walletTabEvents['paymentFailed']();
      }
    }
  },[paymentStatus])

  useEffect(()=>{
 
    if(url.includes('landingPage')){
      if(paymentStatus === 'unpaid'){
        offersEvents['paymentFailed']();
      }else if(paymentStatus=='paid'){ 
        offersEvents['paymentSuccess'](); 
      }
    }

  },[paymentStatus])

  return (
      <Box
          sx={{
            borderRadius: '12px',
            padding: '24px',
            border: `1px solid ${theme.palette.divider}`,
            width: '100%',
          }}
      >
        {
            paymentStatus === 'paid' &&
            <Alert severity={'success'}>
              <AlertTitle>{t(
                  'player.wallet.offer.purchase.success.alert.title')}</AlertTitle>
              {t('player.wallet.offer.purchase.success.alert.description')}
            </Alert>
        }
        {
            paymentStatus === 'unpaid' &&
            <Alert severity={'warning'}>
              <AlertTitle>{t(
                  'player.wallet.offer.purchase.fail.alert.title')}</AlertTitle>
              {t('player.wallet.offer.purchase.fail.alert.description')}
            </Alert>
        }

        <DialogSection
            label={t('player.wallet.offer.purchase.time')}
            icon={AccessTime}
        >
          <Typography>
            {dayjs(details?.createdAt).format('ddd ll LT')}
          </Typography>
        </DialogSection>

        <DialogSection
            label={t('player.wallet.offer.purchase.type')}
            icon={AccountBalanceWalletOutlined}
        >
          <Typography>
            Offer Top Up
          </Typography>
        </DialogSection>

        <DialogSection
            label={t('player.wallet.offer.purchase.offer')}
            icon={LocalOfferOutlined}
        >
          <Stack>
            <Typography>
              {`${t(
                  'wallets.offer.title.pay')} ${APP_DEFAULT_CURRENCY_SYMBOL}${getLocalizedNum(
                  locale, +details.offer.offerAmount)} ${t(
                  'wallets.offer.title.get')} ${APP_DEFAULT_CURRENCY_SYMBOL}${getLocalizedNum(
                  locale, +details.offer.offerValue)}`}
            </Typography>
            <Typography variant={'body2'}>
              {details.offer.description}
            </Typography>
          </Stack>
        </DialogSection>

        <DialogSection
            label={t('player.wallet.offer.purchase.offerAmount')}
            icon={PaymentsOutlined}
        >
          <Typography>{`${APP_DEFAULT_CURRENCY_SYMBOL}${getLocalizedNum(locale,
              +details.offer.offerAmount)}`}</Typography>
        </DialogSection>

        <DialogSection
            label={t('player.wallet.offer.purchase.offerValue')}
            icon={Payment}
        >
          <Typography>{`${APP_DEFAULT_CURRENCY_SYMBOL}${getLocalizedNum(locale,
              +details.offer.offerValue)}`}</Typography>
        </DialogSection>

        <DialogSection
            label={t('player.wallet.detail.section.billing')}
            icon={ReceiptLongOutlined}
            isLastChild={true}
        >
          <Stack spacing={1}>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                sx={{backgroundColor: theme.palette.grey[50]}}
            >
              <Typography color={'text.secondary'}>
                {t('finances.wallet.detail.billing.amount')}
              </Typography>
              <Typography color={'text.secondary'}>
                {`${APP_DEFAULT_CURRENCY_SYMBOL}${getLocalizedNum(
                    locale, +details.offer.offerAmount)}`}
              </Typography>
            </Stack>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
            >
              <Typography color={'text.secondary'}>
                {`${t(
                    'global.billing.platformCharges')} ${details.billing.platformPercentage}`}
              </Typography>
              <Typography color={'text.secondary'}>
                {`${APP_DEFAULT_CURRENCY_SYMBOL}${getLocalizedNum(locale,
                    +details.billing.platformFee)}`}
              </Typography>
            </Stack>

            <Stack
                direction={'row'}
                justifyContent={'space-between'}
            >
              <Typography variant={'h6'}>
                {t('global.billing.totalBilling')}
              </Typography>
              <Typography variant={'h6'}>
                {`${APP_DEFAULT_CURRENCY_SYMBOL}${getLocalizedNum(
                    locale, +details.billing.totalPrice)}`}
              </Typography>
            </Stack>

          </Stack>
        </DialogSection>

        <Stack
            direction={'row'}
            justifyContent={'flex-end'}
            spacing={1}
            mt={2}
        >
          {
              paymentStatus === 'unpaid' &&
              <LoadingButton
                  size={'large'}
                  variant={'contained'}
                  fullWidth
                  loading={isSubmittingRetryPayment}
                  disabled={isSubmittingRetryPayment}
                  onClick={handleRetryPayment}
              >
                <span>{t('global.buttons.actions.retryPayment')}</span>
              </LoadingButton>
          }
        </Stack>
      </Box>
  );
}

export default WalletOfferPurchaseDetails;
