import {Box, Button, Stack, useMediaQuery} from '@mui/material';
import {Link} from 'react-router-dom';
import SiteOnboardingButtons
  from '../../components/_Global/Site/SiteOnboardingButtons';
import SiteUserProfile from '../../components/_Global/Site/SiteUserProfile';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import { signUpEvents } from '../../utils/analyticsEvents';

function LayoutPublicPaymentsHeader() {

  const mobileCollapse = useMediaQuery(`(max-width:376px)`);
  const isAuthenticated = useIsAuthenticated();

  return (
      <Box sx={{
        height: '64px',
        padding: '0 30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <Stack direction={'row'}
               spacing={!mobileCollapse ? 2 : 1}>
          {
              !mobileCollapse &&
              <Button
                  component={Link}
                  to={'/'}
                  variant={'text'}
                  color={'inherit'}
                  disabled
              >
                contact
              </Button>
          }
          {
              (!isAuthenticated) &&
              <SiteOnboardingButtons
              onRegisterFailure={()=>{
                signUpEvents['signUpFailed']()
              }}
              />
          }
          {
              (isAuthenticated) &&
              <SiteUserProfile/>
          }
        </Stack>
      </Box>
  );
}

export default LayoutPublicPaymentsHeader;
