import {
    Alert,
    AlertTitle,
    Box,
    Stack,
    Typography,
    useTheme,
  } from '@mui/material';
  import DialogSection from '../_Global/Dialogs/DialogSection';
  import {
    AccessTime,
    AccountBalanceWalletOutlined,
    LocalOfferOutlined,
    Payment,
    PaymentsOutlined,
    ReceiptLongOutlined,
  } from '@mui/icons-material';
  import {useTranslate} from '@tolgee/react';
  
  import {LoadingButton} from '@mui/lab';
  import {useEffect, useState} from 'react';
  import {useDispatch} from 'react-redux';
  import {
    customersOffersRetryPayment_POST,
  } from '../../vendor/redux/actions/wallet';
  import {useParams} from 'react-router-dom';
  import dayjs from 'dayjs';
  import useLocale from '../../hooks/localization/useLocale';
  import {getLocalizedNum} from '../../utils/localization';
  import {APP_DEFAULT_CURRENCY_SYMBOL} from '../../constants/localization';
  import { walletTabEvents } from '../../utils/analyticsEvents';
  import { offersEvents } from '../../utils/analyticsEvents';
import BillingDetails from '../_Global/Billing/BillingDetails';
  
  function WalletTopupPurchaseDetails({details,paymentStatus}) {
  
    const url = window.location.pathname;
  
    const {t} = useTranslate();
    const theme = useTheme();
    const dispatch = useDispatch();
  
    const {locale} = useLocale();
    dayjs.locale(locale);
 
    const billingEntries = [
      {
        label: t("wallet.topup.billing.amount"),
        amount: details?.amount,
      },
    ];

    useEffect(()=>{
  
      if(!url.includes('landingPage')){
        if(paymentStatus=='paid'){
  
          walletTabEvents['paymentSuccess']();
    
        }else if(paymentStatus=='unpaid'){
          walletTabEvents['paymentFailed']();
        }
      }
    },[paymentStatus])
  
    useEffect(()=>{
   
      if(url.includes('landingPage')){
        if(paymentStatus === 'unpaid'){
          offersEvents['paymentFailed']();
        }else if(paymentStatus=='paid'){ 
          offersEvents['paymentSuccess'](); 
        }
      }
  
    },[paymentStatus])
  
    return (
        <Box
            sx={{
              borderRadius: '12px',
              padding: '24px',
              border: `1px solid ${theme.palette.divider}`,
              width: '100%',
            }}
        >

          {
              paymentStatus === 'paid' &&
              <Alert severity={'success'}>
                <AlertTitle>{t(
                    'wallet.topup.purchase.success.alert.title')}</AlertTitle>
                {t('wallet.topup.purchase.success.alert.description')}
              </Alert>
          }
  
          <DialogSection
              label={t('player.wallet.offer.purchase.type')}
              icon={AccountBalanceWalletOutlined}
          >
            <Typography>
              {t('wallet.topup.paymentStatus.title')}
            </Typography>
          </DialogSection>

          <DialogSection
              label={t('player.wallet.offer.purchase.time')}
              icon={AccessTime}
          >
            <Typography>
              {dayjs(details?.createdAt).format('ddd ll LT')}
            </Typography>
          </DialogSection>
  
          <DialogSection
              label={t('wallet.topup.purchase.amount')}
              icon={PaymentsOutlined}
          >
            <Typography>{`${APP_DEFAULT_CURRENCY_SYMBOL}${getLocalizedNum(locale,
                +details?.amount)}`}</Typography>
          </DialogSection>
  
          <DialogSection
              label={t('player.wallet.detail.section.billing')}
              icon={ReceiptLongOutlined}
              isLastChild={true}
          >
            <BillingDetails
            isLoading={false}
            entries={billingEntries}
            platformPercentage={details?.billing?.platformPercentage ?? ''}
            platformFee={details?.billing?.platformFee ?? ''}
            totalBillingAmount={details?.billing?.totalPrice}
          />
          </DialogSection>
  
        </Box>
    );
  }
  
  export default WalletTopupPurchaseDetails;
  