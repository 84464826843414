import {createContext, useEffect, useMemo} from 'react';
import {io} from 'socket.io-client';
import useUser from '../hooks/access/useUser';

const SocketIOContext = createContext();
const ENDPOINT = process.env.REACT_APP_API_URL;

const ProviderSocketIO = ({children}) => {

  const USER = useUser();
  const ACCESS_TOKEN = USER?.accessToken;

  const socket = useMemo(() => {
    return io(ENDPOINT, {
      path: '/socket.io',
      transports: ['websocket'],
      query: {
        authorization: ACCESS_TOKEN,
      },
    });
  }, [ACCESS_TOKEN]);

  useEffect(() => {

    socket.on('connect', () => {
      console.log(`Socket Provider: Connect - id: ${socket.id}`);
    });

    socket.on('disconnect', () => {
      console.log(`Socket Provider: Disconnect - id: ${socket.id}`);
    });

    socket.on('hello from server', (data) => {
      console.log(`Socket Provider: hello from server: ${data}`);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('hello from server');
      socket.disconnect();
      console.log('Socket Provider socket.disconnect() called');
    };
  }, [socket]);

  return (
      <SocketIOContext.Provider value={socket}>
        {children}
      </SocketIOContext.Provider>
  );

};

export {ProviderSocketIO, SocketIOContext};
