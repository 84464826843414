import {
  BackendFetch,
  DevTools,
  FormatSimple,
  Tolgee,
  TolgeeProvider as Provider,
} from '@tolgee/react';
import {useMemo} from 'react';
import useLocale from '../hooks/localization/useLocale';
import {getIsLocal} from '../utils/environments';

function ProviderTolgee({children}) {

  const {locale} = useLocale();
  const isLocal = getIsLocal();


  const tolgee = useMemo(() => {

    const options = {
      // availableLanguages: ['en', 'nl', 'es', 'fr', 'it'],
      availableLanguages: ['en', 'nl'],
      defaultLanguage: locale,
      defaultNs: 'meet-translation',
      apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
      staticData:{
        'en:meet-translation': ()=>import('../i18n/meet-translation/en.json'),
        'nl:meet-translation': ()=>import('../i18n/meet-translation/nl.json'),
      }
    }

    if (isLocal){
      options.apiKey = process.env.REACT_APP_TOLGEE_API_KEY
    }

    return Tolgee().
        use(DevTools()).
        use(FormatSimple()).
        // use(BackendFetch()).
        init(options);
  }, []);

  return <Provider tolgee={tolgee}>
    {children}
  </Provider>;
}

export default ProviderTolgee;
