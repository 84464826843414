/* eslint-disable camelcase */
import {Record} from 'immutable';
import {
  GET_SPORTS_FAILURE,
  GET_SPORTS_LOADING,
  GET_SPORTS_SUCCESS,
  RESET_REDUX_STATE,
  USER_LOGIN,
  USER_LOGOUT,
  GET_COUNTRY_FAILURE,
  GET_COUNTRY_LOADING,
  GET_COUNTRY_SUCCESS,
} from '../constants';

const userFromLocalStorage = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : null;

const defaultValues = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errMsg: null,
  countries: null,
  sports: null,
  clubs: null,
  user: userFromLocalStorage,
  isAuthenticated: !!userFromLocalStorage,
};

const State = new Record(defaultValues);
const initialState = new State();

function appReducer(state = initialState, action) {
  switch (action.type) {

    case USER_LOGIN:
      return {
        ...state,
        user: action.payload,
        isAuthenticated: true,
      };

    case USER_LOGOUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };

    case GET_SPORTS_LOADING:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isSuccess: false,
        errMsg: null,
      };

    case GET_SPORTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errMsg: null,
        sports: action.payload,
      };

    case GET_SPORTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errMsg: action.payload,
        sports: null,
      };
      case GET_COUNTRY_LOADING:
        return {
          ...state,
          isLoading: true,
          isError: false,
          isSuccess: false,
          errMsg: null,
        };
  
      case GET_COUNTRY_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isError: false,
          isSuccess: false,
          errMsg: null,
          countries: action.payload,
        };
  
      case GET_COUNTRY_FAILURE:
        return {
          ...state,
          isLoading: false,
          isError: false,
          isSuccess: false,
          errMsg: action.payload,
          countries: null,
        };
    case RESET_REDUX_STATE:
      return initialState;

    default:
      return state;
  }
}

export default appReducer;
