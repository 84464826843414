import { lazy } from 'react';
import {Route} from 'react-router-dom';
const PageBookingsRecurrenceDetail = lazy(() => import('../pages/Bookings/PageBookingsRecurrenceDetail'));
const PageBookings = lazy(() => import('../pages/Bookings/PageBookings'));

const RoutesBookings = (
    <>
      <Route path={`/*`} element={<PageBookings/>}/>
      <Route path={`/bookings/*`} element={<PageBookings/>}/>
      <Route path={'/bookings/recurring/recurrence-detail'}
             element={<PageBookingsRecurrenceDetail/>}/>
    </>
);

export default RoutesBookings;
