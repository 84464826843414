import {alpha, Box} from '@mui/material';

function DividerVertical({light}) {
  return (
      <Box
          sx={{
            height: '20px',
            width: '1px',
            background: 'rgba(189, 189, 189, 1)',
          }}
      ></Box>
  );
}

export default DividerVertical;