function FlagNl() {
  return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 85.5 513 342">
        <rect y="85.5" fill="#FFFFFF" width="513" height="342"/>
        <rect y="85.5" fill="#cd1f2a" width="513" height="114"/>
        <rect y="312" fill="#1d4185" width="513" height="114"/>
      </svg>

  );
}

export default FlagNl;
