import {useContext} from 'react';
import {LocaleContext} from '../../providers/ProviderLocale';

function useLocale() {

  const {locale, setLocale} = useContext(LocaleContext);

  return {
    locale,
    setLocale,
  };

}

export default useLocale;
