import {Box, CircularProgress, useTheme} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import ContainerFixed from '../../components/_Global/Containers/ContainerFixed';
import {useParams, useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {
  customersOffersPaymentStatus_GET,
  customersWalletSingle_GET,
  customersWalletTopupPaymentStatus_GET,
} from '../../vendor/redux/actions/wallet';
import {useEffect, useState} from 'react';
import WalletOfferPurchaseDetails
  from '../../components/Wallet/WalletOfferPurchaseDetails';
import PageHeaderClubExperience
  from '../../components/_Global/Page/PageHeaderClubExperience';
import BreadcrumbsStaticClubExperience
  from '../../components/_Global/Breadcrumbs/BreadcrumbsStaticClubExperience';
import PageHeader from '../../components/_Global/Page/PageHeader';
import BreadcrumbsStatic
  from '../../components/_Global/Breadcrumbs/BreadcrumbsStatic';
import WalletTopupPurchaseDetails from '../../components/Wallet/WalletTopupPurchaseDetails';

function PageWalletTopupPurchaseStatus({
                                         experience = 'player-experience' ||
                                         'club-experience',
                                         isClubExperience
                                       }) {

  const {t} = useTranslate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const {clubId,cId} = useParams();
  const [searchParams] = useSearchParams();
  const topupId = searchParams.get('id');
  const paymentStatus = searchParams.get('payment_status');
  
  const [topupDetails, setTopupDetails] = useState();
 
  const isLoading = !topupDetails;

  const breadcrumbs = [
    {
      label: t('player.wallet.breadcrumbs.wallet'),
      route: isClubExperience ? `/club/${clubId}/wallet` :'/wallet',
    },
    {
      label: topupDetails?.wallet?.club?.name
    },
  ];

  const pageTitle = `${t('wallet.topup.paymentStatus.title')} (ID ${topupId})`;

  let domain = process.env.REACT_APP_URL;
  

  function fetchAndSetTransactionDetail() {

    dispatch(customersWalletSingle_GET(topupId, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersWalletSingle_GET Success', res);
      setTopupDetails(res.data.data);
    }

    function cbFail(e) {
      console.log('customersWalletSingle_GET Fail', e);
    }
  }

  useEffect(() => {
    if(cId){
      window.close();
    }else{
      fetchAndSetTransactionDetail();  
    }
  }, [cId]);

  return (
      <Box>
        {
            experience === 'player-experience' &&
            <PageHeader
                breadcrumbsSlot={
                  <BreadcrumbsStatic
                      options={!isLoading && breadcrumbs}
                  />
                }
            />
        }
        {
          (experience === 'club-experience') &&
          <PageHeaderClubExperience
            pageTitle={pageTitle}
            breadcrumbsSlot={
              <BreadcrumbsStaticClubExperience
                  options={!isLoading && breadcrumbs}
              />
            }
        />
        }
        <ContainerFixed justify={'left'}>
          <Box sx={{maxWidth: '600px'}}>
            {
                isLoading &&
                <Box
                    sx={{
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: '12px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      minHeight: '500px',
                    }}
                >
                  <CircularProgress/>
                </Box>
            }
            {
                !isLoading &&
                <WalletTopupPurchaseDetails
                    details={topupDetails}
                    topupId={topupId}
                    paymentStatus={paymentStatus}
                />
            }
          </Box>

        </ContainerFixed>
      </Box>
  );
}

export default PageWalletTopupPurchaseStatus;
