import {statusError, statusLoading, statusSuccess} from './status';
import {
  POLICIES_FAILURE,
  POLICIES_LOADING,
  POLICIES_SUCCESS,
} from '../constants';

import axios from 'axios';

export const clubsPolicyList_GET = (clubId, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const url = `/clubs/policy/list/${clubId}`;
      dispatch(statusLoading(POLICIES_LOADING));
      const response = await axios.get(url);
      dispatch(statusSuccess(POLICIES_SUCCESS, response.data.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(POLICIES_FAILURE, e));
    }
  };
};



