import { lazy } from 'react';
import {Route} from 'react-router-dom';

const PageProfile = lazy(() => import('../pages/Profile/PageProfile'));
const PageCustomerProfileEdit = lazy(() => import('../pages/Profile/PageProfileEdit'));

const RoutesSettingsProfile = (
    <>
      <Route path={`/settings/profile`} element={<PageProfile/>}/>
      <Route path={`/settings/profile/edit`}
             element={<PageCustomerProfileEdit/>}/>
    </>
);

export default RoutesSettingsProfile;
