import {Controller, FormProvider, useFormContext} from 'react-hook-form';
import {Grid, InputAdornment, TextField} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useEffect} from 'react';
import FirstName from '../_Global/Inputs/FirstName';
import LastName from '../_Global/Inputs/LastName';
import Email from '../_Global/Inputs/Email';
import Phone from '../_Global/Inputs/Phone';
import DateOfBirth from '../_Global/Inputs/DateOfBirth';
import Gender from '../_Global/Inputs/Gender';

function FormRegistration({formMethods}) {
  return (
      <FormProvider {...formMethods}>
        <Grid
            container
            columnSpacing={2}
            rowSpacing={2}
        >
          <Grid item xs={12}>
            <FirstName/>
          </Grid>
          <Grid item xs={12}>
            <LastName/>
          </Grid>
          <Grid item xs={12}>
            <Email/>
          </Grid>
          <Grid item xs={12}>
            <Phone/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateOfBirth/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Gender/>
          </Grid>
          <Grid item xs={12}>
            <Password/>
          </Grid>
          <Grid item xs={12}>
            <PasswordConfirm/>
          </Grid>
        </Grid>
      </FormProvider>
  );
}

function Password() {
  const {
    control,
    setValue,
    trigger,
    watch,
    formState: {errors, isSubmitted},
  } = useFormContext();
  const {t} = useTranslate();

  const {isPasswordVisible} = watch();

  const handleTogglePasswordVisibility = () => {
    setValue('isPasswordVisible', !isPasswordVisible);
  };

  return (
      <Controller
          name={'password'}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('register.fields.password.error'),
            },
            validate: {
              hasOneCapital: (value) => {
                return /[A-Z]/.test(value) ||
                    t('register.fields.password.validation');
              },
              isEightChars: (value) => {
                return value?.length >= 8 ||
                    t('register.fields.password.validation');
              },
              hasNum: (value) => {
                return /\d/.test(value) ||
                    t('register.fields.password.validation');
              },
            },
          }}
          render={({field, fieldState}) => {
            return <TextField
                fullWidth
                required
                label={t('register.fields.password.label')}
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder={t('register.fields.password.placeholder')}
                size="medium"
                error={!!errors['password']}
                helperText={!!errors['password'] && errors['password'].message}
                InputProps={{
                  endAdornment: <InputAdornment
                      position="end"
                      sx={{cursor: 'pointer'}}
                      onClick={handleTogglePasswordVisibility}
                  >
                    {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                  </InputAdornment>,
                }}
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value);
                }}
            />;
          }}
      />
  );
}

function PasswordConfirm() {
  const {
    control,
    trigger,
    watch,
    formState: {errors, isSubmitted},
  } = useFormContext();
  const {t} = useTranslate();

  const {isPasswordVisible, password} = watch();

  useEffect(() => {
    if (isSubmitted) {
      trigger('passwordConfirm');
    }
  }, [password]);

  return (
      <Controller
          name={'passwordConfirm'}
          control={control}
          rules={{
            validate: {
              hasValue: (value) => {
                return value?.length > 0 ||
                    t('register.fields.passwordConfirm.error.noValue');
              },
              matches: (value) => {
                return password === value ||
                    t('register.fields.passwordConfirm.error.noMatch');
              },
            },
          }}
          render={({field}) => {
            return <TextField
                fullWidth
                required
                label={t('register.fields.passwordConfirm.label')}
                type={isPasswordVisible ? 'text' : 'password'}
                placeholder={t('register.fields.passwordConfirm.placeholder')}
                size="medium"
                error={!!errors['passwordConfirm']}
                helperText={!!errors['passwordConfirm'] &&
                    errors['passwordConfirm'].message}
                {...field}
            />;
          }}
      />
  );
}

export default FormRegistration;