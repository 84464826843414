import {createTheme} from '@mui/material/styles';

const {palette} = createTheme();

/*Typography*/
let theme = createTheme({
  typography:{
    fontFamily:"Public Sans, sans-serif",
    h1:{
      fontSize:"96px",
      fontWeight:"300",
    },
    h2:{
      fontSize:"60px",
      fontWeight:"300",
    },
    h3:{
      fontSize:"48px",
      fontWeight:"400",
    },
    h4:{
      fontSize:"34px",
      fontWeight:"400",
    },
    h5:{
      fontSize:"24px",
      fontWeight:"400",
    },
    "h5-bold":{
      fontSize:"24px",
      fontWeight:"700",
    },
    h6:{
      fontSize:"20px",
      fontWeight:"500",
    },
    "h6-bold":{
      fontSize:"20px",
      fontWeight:"700",
    },
    subtitle1:{
      fontSize:"17px",
      fontWeight:"400",
    },
    subtitle2:{
      fontSize:"14px",
      fontWeight:"500",
    },
    "body1-emphasis": {
      fontSize: "16px",
      fontWeight:"500",
    }
  },
});

/*Palette*/
theme = createTheme(theme,{
  palette: {
    primary: {
      main: '#2196F3',
      dark: '#1874D0',
      light: '#79D0FB',
    },
    secondary: {
      main: '#193291',
      dark: '#0C1B68',
      light: '#D0DCF9',
    },
    contrastText: palette.augmentColor({color:{
      main:"#fff"
      }}),
    error: {
      main: '#FF6066',
      dark: '#931E42',
      light: '#FFCDBF',
    },
    // warning: {
    //   main: '#FFE01C',
    //   dark: '#DBBD14',
    //   light: '#FFF6A4',
    // },
    // info: {
    //   main: '#277BF9',
    //   dark: '#1346B3',
    //   light: '#A8D5FE',
    // },
    success: {
      main: '#26B55D',
      dark: '#1A7E41',
      light: '#51C37D',
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      'A100': '#f5f5f5',
      'A200': '#eeeeee',
      'A400': '#bdbdbd',
      'A700': '#616161',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.55)',
      disabled: 'rgba(0, 0, 0, 0.28)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#fff',
    },
    action: {
      active: 'rgba(0,0,0,0.54)',
      hover: 'rgba(0,0,0,0.04)',
      selected: 'rgba(0,0,0,0.08)',
      disabled: 'rgba(0,0,0,0.26)',
      disabledBackground: 'rgba(0,0,0,0.12)',
      focus: 'rgba(0,0,0,0.12)',
    },
  },
});

/*Custom Styles*/
theme = createTheme(theme, {
  palette: {
    neutral: {
      primary: '#F2F5F7',
    },
    skeleton: 'rgba(0, 0, 0, 0.12)',
    icon: {
      primary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.3)',
    },
  },
  typography:{
    body2:{
      color: theme.palette.text.secondary,
    }
  }
});

/*Component Overrides*/
theme = createTheme(theme, {
  components: {
    MuiTypography:{
      defaultProps:{
        variantMapping:{
          // currently, you need to provide all of the default mapping because it will replace, not merge.
          h1: "h1",
          h2: "h2",
          h3: "h3",
          h4: "h4",
          h5: "h5",
          h6: "h6",
          subtitle1: "h6",
          subtitle2: "h6",
          body1: "p",
          body2: "p",
          inherit: "p",
          // You have to add this line
          // otherwise the `span` (display `inline` by default)
          // cannot have margin.
          "h5-bold": "h5",
          "h6-bold": "h6",
          "body1-emphasis": "p"
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: ({ownerState}) => {

          const size = ownerState.size;
          const variant = ownerState.variant;

          let defaults = {
            textTransform: 'none',
            boxShadow: 'none',
            '&:hover': {
              boxShadow: 'none',
            },
          };

          if (size === 'large') {
            if (variant === 'text') {
              return {
                ...defaults,
                padding: '9px 12px',
              };
            } else {
              return {
                ...defaults,
                padding: '9px 20px',
              };
            }
          }

          if (size === 'medium') {
            if (variant === 'text') {
              return {
                ...defaults,
                padding: '7px 8px',
              };
            } else {
              return {
                ...defaults,
                padding: '7px 16px',
              };
            }
          }

          if (size === 'small') {
            if (variant === 'text') {
              return {
                ...defaults,
                padding: '6px 6px',
              };
            } else {
              return {
                ...defaults,
                padding: '6px 12px',
              };
            }
          }

        },
      },
    },

    MuiMenu: {
      defaultProps: {
        elevation: 3,
      },
    },

    MuiSelect: {
      defaultProps: {
        MenuProps: {
          elevation: 3,
          disableScrollLock: false,
          MenuListProps: {
            sx: {
              maxHeight: '300px',
            },
          },
        },
      },
    },

    MuiAlert: {
      styleOverrides: {
        root: {
          ".MuiAlertTitle-root":{
            fontWeight:600
          },
          ".MuiAlert-action":{
            paddingTop:0
          },
          [theme.breakpoints.down('sm')]: {
            // flexDirection:"column"
          },
        },
      },
    },

    MuiModal: {
      defaultProps: {
        disableScrollLock: true,
      },
    },

    MuiDialogTitle: {
      defaultProps: {
        variant: 'h5',
        sx: {
          padding: '24px',
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiPaper-root[role=dialog]': {
            borderRadius: '12px',
          },
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '0 26px',
          borderBottom: 'none',
        },
      },
      defaultProps: {
        dividers: true,
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '24px',
          ['@media (max-width: 450px)']: {
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '16px',
            '&> *': {
              width: '100%',
              marginLeft: '0 !important',
              marginBottom: '8px !important',
            },
          },
        },
      },
    },

    MuiPickersPopper: {
      defaultProps: {
        PaperProps: {
          elevation: 3,
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          '.MuiMenuItem-root': {
            '.MuiTouchRipple-child': {
              backgroundColor: theme.palette.primary.light,
            },
            '&.Mui-selected': {
              '.MuiTouchRipple-child': {},
              '&:hover': {},
            },
            '&:focus': {},
            '&:hover': {},
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiOutlinedInput-root': {
            '& fieldset': {},
            '&:hover': {
              '& fieldset': {},
            },
          },
          '&.Mui-focused': {
            '&.MuiOutlinedInput-root': {
              '& fieldset': {},
              '&:hover': {
                '& fieldset': {},
              },
            },
          },
        },
      },
    },

    /*Buttons*/

    /*Data Grid*/
    MuiDataGrid: {
      styleOverrides: {
        root: {

          borderStyle: 'none',

          '.MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.grey[50],
            '.MuiDataGrid-columnHeaderTitleContainer': {
              color: theme.palette.grey[800],
            },
          },
          '.MuiDataGrid-row': {
            '.MuiDataGrid-cell': {
              color: theme.palette.text.primary,
            },
            '&:last-child': {
              '.MuiDataGrid-cell': {},
            },
          },
          '.MuiDataGrid-cell:focus,.MuiDataGrid-cell:focus-within,.MuiDataGrid-columnHeader:focus,.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
          '.MuiTablePagination-root': {
            height: '99%',
            overflow: 'hidden',
          },
          '.MuiDataGrid-toolbarContainer': {
            paddingTop: '8px',
            paddingBottom: '8px',
          },
          '.MuiDataGrid-footerContainer': {
            minHeight: '64px',
            '.MuiTablePagination-root': {
              display: 'flex',
              alignItems: 'center',
            },
          },

        },
      },
    },

    /*Table*/
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'separate',
        },
      },
    },

    MuiTableCell: {
      styleOverrides: {
        body: {
          borderColor: theme.palette.divider,
          ':first-of-type': {},
          ':last-of-type': {},
        },
        head: {
          backgroundColor: theme.palette.grey[50],
          ':first-of-type': {
            borderTopLeftRadius: '4px',
          },
          ':last-of-type': {
            borderTopRightRadius: '4px',
          },
        },
      },
    },

    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover': {
            position: 'relative',
            background: '#fff',
            '&:hover': {
              background: theme.palette.grey['50'],
            },
          },
        },
      },
    },

    MuiTablePagination: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
          borderTop: '0',
        },
      },
    },

    /*Chip*/
    MuiChip: {
      styleOverrides: {
        root: {
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center',
          '.MuiChip-label': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'visible',
            lineHeight: 1,
          },
          textTransform: 'capitalize',
        },
      },
    },

    /*Skeleton*/
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[200],
        },
      },
    },

    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },

  },

});

export default theme;
