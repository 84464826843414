import {Controller, useFormContext} from 'react-hook-form';
import {useTranslate} from '@tolgee/react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';

function Gender({disabled = false}) {
  const {control, formState: {errors}} = useFormContext();
  const {t} = useTranslate();

  return (
      <FormControl
          fullWidth
          size={'medium'}
          required
      >
        <InputLabel error={!!errors['gender']}>
          {t('register.fields.gender.label')}
        </InputLabel>
        <Controller
            defaultValue={''}
            name={'gender'}
            control={control}
            rules={
                !disabled && {
                  required: {
                    value: true,
                    message: t('register.fields.gender.error'),
                  },
                }
            }
            render={({field}) => {
              return (<Select
                      error={!!errors['gender']}
                      label={t('register.fields.gender.label')}
                      {...field}
                      value={field.value}
                      disabled={disabled}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                  >
                    <MenuItem value={'male'}>
                      {t('global.types.genders.male')}
                    </MenuItem>
                    <MenuItem value={'female'}>
                      {t('global.types.genders.female')}
                    </MenuItem>
                    <MenuItem value={'other'}>
                      {t('global.types.genders.other')}
                    </MenuItem>
                  </Select>
              );
            }}
        />
        {!!errors['gender'] &&
            <FormHelperText error>{errors['gender'].message}</FormHelperText>}
      </FormControl>
  );

}

export default Gender;
