import {Controller, useFormContext} from 'react-hook-form';
import {useTranslate} from '@tolgee/react';
import {containsNonLetters} from '../../../utils/validation';
import {TextField} from '@mui/material';

function LastName() {
  const {control, formState: {errors}} = useFormContext();
  const {t} = useTranslate();

  return (
      <Controller
          name={'lastName'}
          control={control}
          rules={{
            required: {
              value: true,
              message: t('register.fields.lastName.error'),
            },
            validate: {
              containsOnlyLetters: (value)=>{
                if (containsNonLetters(value)){
                  return t('register.fields.error.name.ContainsNonLetters')
                }else {
                  return true;
                }
              }
            }
          }}
          render={({field}) => {
            return <TextField
                fullWidth
                inputProps={{maxLength: 30, min: 0}}
                required
                label={t('register.fields.lastName.label')}
                type="text"
                placeholder={t('register.fields.lastName.placeholder')}
                size="medium"
                error={!!errors['lastName']}
                helperText={!!errors['lastName'] && errors['lastName'].message}
                {...field}
            />;
          }}
      />
  );
}

export default LastName;
