import {OnboardingContext} from '../../providers/ProviderOnboarding';
import {useContext} from 'react';

function useOnboardingContext() {
  const {
    isOpenLoginDialog,
    setIsOpenLoginDialog,
    isOpenForgotDialog,
    setIsOpenForgotDialog,
    isOpenRegisterDialog,
    setIsOpenRegisterDialog,
    willShowForgotPassword,
    setWillShowForgotPassword,
    willShowLoginDialog,
    setWillShowLoginDialog,
    willShowRegisterDialog,
    setWillShowRegisterDialog,
    preFillEmail,
    setPreFillEmail
  } = useContext(OnboardingContext);

  return {
    isOpenLoginDialog,
    setIsOpenLoginDialog,
    isOpenForgotDialog,
    setIsOpenForgotDialog,
    isOpenRegisterDialog,
    setIsOpenRegisterDialog,
    willShowForgotPassword,
    setWillShowForgotPassword,
    willShowLoginDialog,
    setWillShowLoginDialog,
    willShowRegisterDialog,
    setWillShowRegisterDialog,
    preFillEmail,
    setPreFillEmail
  };

}

export default useOnboardingContext;