import {Box} from '@mui/material';
import {Outlet, useLocation} from 'react-router-dom';
import LayoutClubHeader from './LayoutClubHeader';
import LayoutClubPage from './LayoutClubPage';
import {useEffect, useRef, useState} from 'react';
import LayoutClubMobileHeader from './LayoutClubMobileHeader';
import { Suspense } from 'react';

function LayoutClub() {

  const pageRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {

    if (pageRef.current){
       pageRef.current.scrollTo({
         top: 0,
         behavior: "instant",
       });
    }

  }, [pathname]);

  return (
      <Box
          ref={pageRef}
          sx={{
            width: '100vw',
            height: '100vh',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
      >
       
        <LayoutClubHeader/>
        <Box component={'main'}>
          <Suspense fallback={<h1></h1>}>
          <LayoutClubPage>
            <Outlet/>
          </LayoutClubPage>
          </Suspense>
        </Box>
      </Box>
  );
}

export default LayoutClub;
