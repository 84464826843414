import {Box, Stack} from '@mui/material';
import {LAYOUT_MAIN__PUBLIC_HEADER__HEIGHT} from '../../constants/ui';
import ContainerFullWidth from '../../components/_Global/Containers/ContainerFullWidth';
import SiteLanguageSwitcher from '../../components/_Global/Site/SiteLanguageSwitcher';
import SiteOnboardingButtons from '../../components/_Global/Site/SiteOnboardingButtons';

function LayoutMainPublicHeader({shouldRedirectOnLogin}) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <ContainerFullWidth>
                <Box
                    sx={{
                        height: LAYOUT_MAIN__PUBLIC_HEADER__HEIGHT,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Stack direction={'row'} ml={'auto'} spacing={1} alignItems={'center'}>
                        <SiteLanguageSwitcher/>
                        {/*<SiteOnboardingButtons*/}
                        {/*    size={'large'}*/}
                        {/*    color={'primary'}*/}
                        {/*    showDivider={false}*/}
                        {/*    shouldRedirect={shouldRedirectOnLogin}*/}
                        {/*/>*/}
                    </Stack>
                </Box>
            </ContainerFullWidth>

        </Box>
    );
}

export default LayoutMainPublicHeader;