export const LAYOUT_PUBLIC_MAIN__HEADER__TOP__HEIGHT = '64px';
export const LAYOUT_MAIN__HEADER__TOP__HEIGHT = '48px';
export const LAYOUT_MAIN__PUBLIC_HEADER__HEIGHT = '64px';
export const LAYOUT_MAIN__HEADER__BOTTOM__HEIGHT = '50px';
export const LAYOUT_MAIN__MOBILE_HEADER__HEIGHT = '48px';

export const LAYOUT_LOGIN__HEADER__TOP__HEIGHT = '64px';


export const LAYOUT_MAIN__PAGE___PADDING_TOP = '0';
export const LAYOUT_MAIN__PAGE_PADDING_BOTTOM = '40px';
export const LAYOUT_MAIN__PAGE_HEADER___HEIGHT = '74px';
export const LAYOUT_MAIN__PAGE_HEADER___MARGIN_BOTTOM = '32px';
export const PAGE_HEADER_HEIGHT = '96px'
