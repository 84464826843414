import {Box, Stack, Typography} from '@mui/material';
import {PAGE_HEADER_HEIGHT} from '../../../constants/ui';
import useBreakpoint from '../../../hooks/ui/useBreakpoint';

function PageHeader({
                      breadcrumbsSlot: BreadcrumbsSlot,
                      actionsSlot: ActionsSlot,
                      collapseActions = true,
                      description,
                    }) {

  const {isMdDown} = useBreakpoint();

  return (
      <Box sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        height: !isMdDown ? PAGE_HEADER_HEIGHT : '',
      }}>
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={(isMdDown && collapseActions) ? 'flex-start' : 'space-between'}
            flexWrap={'wrap'}
            sx={{
              width: '100%',
              ...(isMdDown) && {
                pt: 2,
                pb:1,
              },
            }}
        >
          <Stack
              spacing={'4px'}
              sx={{
                ...(isMdDown && collapseActions) && {
                  width: '100%',
                },
              }}
          >
            <Box>
              {BreadcrumbsSlot}
            </Box>
            {
                description &&
                <Typography
                    color={'text.secondary'}
                    sx={{
                      fontSize:"clamp(0.88rem, calc(0.81rem + 0.34vw), 1.00rem)"
                    }}
                >
                  {description}
                </Typography>
            }
          </Stack>

          <Stack
              mt={(isMdDown && collapseActions) && 2}
              direction={'row'}
              columnGap={1}
              alignItems={'center'}
              rowGap={1}
              flexWrap={'wrap'}
              sx={{
                ...(isMdDown && collapseActions) && {
                  width: '100%',
                },
              }}
          >
            {ActionsSlot}
          </Stack>
        </Stack>
      </Box>
  );
}

export default PageHeader;
