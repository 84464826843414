import {Box, Breadcrumbs, Link as LinkMUI, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {ChevronRight} from '@mui/icons-material';

function BreadcrumbsStaticClubExperience({options}) {

  const linkStyles = {
    textDecoration: 'none',
    fontSize: '16px',
    color: 'text.secondary',
    fontWeight: 500,
  };

  return <Breadcrumbs separator={<ChevronRight/>} sx={{mb: '20px'}}>
    {
      (options || []).map((option, index) => {
        return (
            <Box
                key={index}
                display={'inline'}
            >
              <LinkMUI
                  component={option?.route ? Link : Typography}
                  to={option?.route}
                  sx={{
                    ...linkStyles,
                    display: 'inline',
                    verticalAlign: 'middle',
                    ...(option?.route) && {
                      '&:hover': {
                        color: 'text.primary',
                      },
                    },
                  }}
              >
                {option.label}
              </LinkMUI>
            </Box>
        );
      })
    }
  </Breadcrumbs>;
}

export default BreadcrumbsStaticClubExperience;
