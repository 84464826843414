import {Alert, AlertTitle} from '@mui/material';
import {useTranslate} from '@tolgee/react';

function ForgotPasswordSubmitSuccessAlert({email}) {

  const {t} = useTranslate();

  return (
      <Alert severity={'success'}>
        <AlertTitle>{t('passwordReset.success.title')}</AlertTitle>
        {`${t('passwordReset.success.p1')} `}<b>{email}. </b>
        {t('passwordReset.success.p2')}
      </Alert>
  );
}

export default ForgotPasswordSubmitSuccessAlert;