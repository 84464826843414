import {
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {useTranslate} from '@tolgee/react';
import {useSnackbar} from 'notistack';
import {Controller, useForm} from 'react-hook-form';
import {useEffect, useState} from 'react';
import {validate} from 'email-validator';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {TransitionSlide} from '../../vendor/mui/transitions';
import {useDispatch} from 'react-redux';
import DialogBase from '../../components/_Global/Dialogs/DialogBase';
import useLogin from '../../hooks/onboarding/useLogin';
import { forgotPasswordEvents, signUpEvents } from '../../utils/analyticsEvents';

function DialogLogin({
                       isOpenDialog,
                       setIsOpenDialog,
                       showForgotPasswordDialog,
                       willShowForgotPassword,
                       setWillShowForgotPassword,
                       willShowRegisterDialog,
                       setWillShowRegisterDialog,
                       showRegisterDialog,
                       preFillEmail,
                       setPreFillEmail,
                       onLoginSuccess = () => {},
                     }) {

  const {t} = useTranslate();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: {errors, isSubmitting},
  } = useForm();

  const {handleLogin, passwordError, emailError} = useLogin(handleLoginSuccess);

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  function handleLoginSuccess() {
    handleCloseDialog();
    onLoginSuccess();
  }

  function handleAfterExit() {
    if (willShowForgotPassword) {
      showForgotPasswordDialog();
      forgotPasswordEvents['openPopup']();
    }
    if (willShowRegisterDialog) {
      showRegisterDialog();
    }
  }

  const handleLoginSubmit = () => {
    return handleSubmit(async ({email, password}) => {
          return handleLogin(email, password);
        },
        (errors) => {
          enqueueSnackbar(t('alerts.globalErrorMessage'), {variant: 'error'});
        },
    )();
  };

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((cur) => !cur);
  };

  const handleEnterKeySubmit = (e) => {
    e.key === 'Enter' && handleLoginSubmit();
  };

  function handleRegisterButtonClick() {
    setWillShowRegisterDialog(true);
    signUpEvents['openSignupPopup']();
  }

  useEffect(() => {
    if (emailError) {
      setError('email', emailError);
    }
    if (passwordError) {
      setError('password', passwordError);
    }
  }, [emailError, passwordError]);


  useEffect(()=>{

    if(!!isOpenDialog && preFillEmail?.length){
      setValue('email',preFillEmail);
    }
  },[isOpenDialog,preFillEmail])

  return (
      <DialogBase
          isOpen={isOpenDialog}
          isSubmitting={isSubmitting}
          dividers={false}
          transitionComponent={TransitionSlide}
          onExited={handleAfterExit}
          onClose={handleCloseDialog}
          title={t('authFlow.player.login.heading')}
          contentSlot={
            <Stack spacing={2} mt={1}>
              <Controller
                  name={'email'}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('authFlow.fields.errorEmail'),
                    },
                    validate: {
                      invalid: (value) => {
                        return validate(value) ||
                            t('validation.emailValidation');
                      },
                    },
                  }}
                  render={({field}) => {
                    return <TextField
                        autoFocus
                        fullWidth
                        required
                        label={t('authFlow.fields.labelEmail')}
                        type="email"
                        placeholder="jhon@gmail.com"
                        size="medium"
                        error={!!errors['email']}
                        helperText={!!errors['email'] &&
                            errors['email'].message}
                        onKeyDown={handleEnterKeySubmit}
                        {...field}
                    />;
                  }}
              />
              <Controller
                  name={'password'}
                  rules={{
                    required: {
                      value: true,
                      message: t('authFlow.fields.passwordError'),
                    },
                  }}
                  control={control}
                  render={({field}) => {
                    return <TextField
                        required
                        fullWidth
                        label={t('authFlow.fields.labelPassword')}
                        size="medium"
                        placeholder={t('authFlow.fields.labelPassword')}
                        type={isPasswordVisible ? 'text' : 'password'}
                        error={!!errors['password']}
                        helperText={errors['password'] &&
                            errors['password'].message}
                        onKeyDown={handleEnterKeySubmit}
                        InputProps={{
                          endAdornment: <InputAdornment
                              position="end"
                              sx={{cursor: 'pointer'}}
                              onClick={handleTogglePasswordVisibility}
                          >
                            {isPasswordVisible ?
                                <VisibilityOff/> :
                                <Visibility/>}
                          </InputAdornment>,
                        }}
                        {...field}
                    />;
                  }}
              />
              <Button
                  size={'small'}
                  variant={'text'}
                  sx={{alignSelf: 'flex-start'}}
                  onClick={() => {
                    setWillShowForgotPassword(true);
                  }}
              >
                {t('authFlow.forgotText')}
              </Button>

            </Stack>
          }
          actionsSlot={<Button
              fullWidth
              variant="contained"
              size={'large'}
              onClick={handleLoginSubmit}
              disabled={isSubmitting}
          >
            {t('buttons.signButton')}
          </Button>
          }
          altActionsSlot={
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <Typography
                  color={'text.secondary'}
              >
                {t('page.login.newUser')}
              </Typography>
              <Typography
                  color={'primary'}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                  onClick={handleRegisterButtonClick}
              >
                {t('global.buttons.actions.signUp')}
              </Typography>
            </Stack>
          }
      />
  );
}

export default DialogLogin;
