import {Skeleton, Stack, Typography, useTheme} from '@mui/material';
import {useTranslate} from '@tolgee/react';

import {toFixedNoRounding} from '../../../utils/math';
import useLocale from '../../../hooks/localization/useLocale';
import dayjs from 'dayjs';
import {getLocalizedNum} from '../../../utils/localization';
import {APP_DEFAULT_CURRENCY_SYMBOL} from '../../../constants/localization';

function BillingDetails({
                          isLoading,
                          entries,
                          isWalletPayment,
                          isCounterPayment,
                          discount,
                          platformFee,
                          platformPercentage,
                          totalBillingAmount,
                          walletDebitedAmount,
                          counterPaidAmount
                        }) {

  const {t} = useTranslate();
  const theme = useTheme();
  const {locale} = useLocale();
  dayjs.locale(locale);


  if (isLoading) {
    return (
        <Stack>
          <Stack
              spacing={.5}
              mb={1}
          >
            {
              [0, 0, 0, 0].map((el, i) => {
                return <EntrySkeleton key={i}/>;
              })
            }
          </Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'h6'}>
              <Skeleton width={'200px'}/>
            </Typography>
            <Typography variant={'h6'}>
              <Skeleton width={'50px'}/>
            </Typography>
          </Stack>
        </Stack>
    );
  }

  if (!isLoading) {
    return (
        <Stack>
          <Stack
              spacing={.5}
              mb={1}
              sx={{
                'div:nth-of-type(odd)': {
                  backgroundColor: theme.palette.grey[50],
                },
              }}
          >
            {
              entries.map((entry, i) => {
                return <Entry entry={entry} key={i}/>;
              })
            }

            {/*Discount Entry*/}
            {
              (typeof discount !== 'undefined') &&
              <Entry
                  entry={{
                    label: `${t('global.components.billing.discount')}`,
                    amount: discount || toFixedNoRounding(0, 2),
                    isNegative: discount > 0
                  }}
              />
            }

            {/*Platform Percentage Entry*/}
            
                <Entry
                    entry={{
                      label: `${t(
                          'global.components.billing.platformCharges')} ${String(platformPercentage).replace('%','') ||
                      0}%`,
                      amount: platformFee || 0,
                    }}
                />
            
            {/*Wallet Credit Entry*/}
            {
                isWalletPayment &&
                <Entry entry={{
                  label: `${t('global.components.billing.walletCredit')}`,
                  amount: walletDebitedAmount,
                  isNegative: true,
                }}/>
            }
            
            {
                isCounterPayment &&
                <Entry entry={{
                  label: `${t('global.components.billing.counterCredit')}`,
                  amount: counterPaidAmount,
                  isNegative: true,
                }}/>
            }
          </Stack>

          {/*Total Billing*/}
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'h6'}>
              {t('global.components.billing.totalBilling')}
            </Typography>
            <Typography variant={'h6'}>
         
                  <>
                    {APP_DEFAULT_CURRENCY_SYMBOL}{getLocalizedNum(locale,
                      totalBillingAmount??0)}
                  </>
              

            </Typography>
          </Stack>
        </Stack>
    );
  }
}

function Entry({entry}) {

  const {locale} = useLocale();
  dayjs.locale(locale);

  const isNegative = entry?.isNegative;

  return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography color={'text.secondary'}>
          {entry.label}
        </Typography>
        <Typography color={isNegative ? 'error.main' : 'text.secondary'}>
          {`${isNegative ?
              '-' :
              ''} ${APP_DEFAULT_CURRENCY_SYMBOL}${getLocalizedNum(
              locale, +entry.amount)}`}
        </Typography>
      </Stack>
  );
}

function EntrySkeleton() {
  return (
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography color={'text.secondary'}>
          <Skeleton width={'200px'}/>
        </Typography>
        <Typography color={'text.secondary'}>
          <Skeleton width={'50px'}/>
        </Typography>
      </Stack>
  );
}

export default BillingDetails;
