import {alpha, Box, Stack, Typography, useTheme} from '@mui/material';
import {DomainDisabled} from '@mui/icons-material';
import {useTranslate} from '@tolgee/react';
import SiteLanguageSwitcher from '../_Global/Site/SiteLanguageSwitcher';

function ClubNotExists() {

  const theme = useTheme();
  const {t} = useTranslate();

  return (
    <Box>
      <Stack direction={'row'} justifyContent={'end'} mr={2}>
        <SiteLanguageSwitcher />
      </Stack>
      <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign:'center',
            p:2,
          }}
      >
        <Stack alignItems={'center'}>
          <Box
              sx={{
                width: '80px',
                height: '80px',
                backgroundColor: alpha(theme.palette.primary.main, .08),
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb:"20px"
              }}
          >
            <DomainDisabled color={'primary'} sx={{
              width: '35px',
              height: '35px',
            }}/>
          </Box>
          <Typography
              fontWeight={700}
              fontSize={'46px'}
              mb={'6px'}
          >
            {t('clubNotExists.title')}
          </Typography>
          <Typography color={'text.secondary'} fontSize={'24px'}>
            {t('clubNotExists.description')}
          </Typography>

        </Stack>
      </Box>
      </Box>
  );

}

export default ClubNotExists;