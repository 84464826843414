import {Box, Stack, useTheme} from '@mui/material';
import SiteUserProfile from '../../components/_Global/Site/SiteUserProfile';
import {LAYOUT_MAIN__HEADER__TOP__HEIGHT} from '../../constants/ui';
import ContainerFullWidth
  from '../../components/_Global/Containers/ContainerFullWidth';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import SiteOnboardingButtons
  from '../../components/_Global/Site/SiteOnboardingButtons';
import { signUpEvents } from '../../utils/analyticsEvents';

function LayoutMainHeaderTop() {

  const theme = useTheme();
  const isAuthenticated = useIsAuthenticated();

  return (
      <Box
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.neutral.primary,
          }}
      >
        <ContainerFullWidth>
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: LAYOUT_MAIN__HEADER__TOP__HEIGHT,
              }}
          >
            {/*<SiteLogo size={'sm'}/>*/}
            <Stack
                sx={{marginLeft: 'auto'}}
                direction={'row'}
                alignItems={'center'}
                spacing={2}
            >
              {
                  isAuthenticated &&
                  <SiteUserProfile/>
              }
              {
                  !isAuthenticated &&
                  <SiteOnboardingButtons
                  onRegisterFailure={()=>{
                    signUpEvents['signUpFailed']()
                  }}
                  />
              }
            </Stack>
          </Box>
        </ContainerFullWidth>
      </Box>

  );
}

export default LayoutMainHeaderTop;
