import {
  Alert,
  Box,
  Button,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {Controller, useForm} from 'react-hook-form';
import {validate} from 'email-validator';
import LayoutOnboarding from '../../layouts/LayoutOnboarding/LayoutOnboarding';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useEffect, useState} from 'react';
import {useTranslate} from '@tolgee/react';
import {LoadingButton} from '@mui/lab';
import useLogin from '../../hooks/onboarding/useLogin';

const PageLogin = () => {

  const {t} = useTranslate();
  const navigateTo = useNavigate();
  const location = useLocation();
  const {enqueueSnackbar} = useSnackbar();

  const {
    handleSubmit,
    control,
    setError,
    formState: {errors, isSubmitting: isSubmittingForm},
  } = useForm();

  const {handleLogin, passwordError, emailError} = useLogin();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const isLoading = isSubmittingForm;

  function handleLoginSubmit() {
    return handleSubmit(async ({email, password}) => {
          return handleLogin(email, password);
        },
        (errors) => {
          enqueueSnackbar(t('alerts.globalErrorMessage'), {variant: 'error'});
        },
    )();
  }

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((cur) => !cur);
  };

  const handleEnterKeySubmit = (e) => {
    e.key === 'Enter' && handleLoginSubmit();
  };

  useEffect(() => {
    if (emailError) {
      setError('email', emailError);
    }
    if (passwordError) {
      setError('password', passwordError);
    }
  }, [emailError, passwordError]);

  return (<>
        <LayoutOnboarding
            isLoading={isLoading}
            title={'Sign In'}
            actionSlot={<RegisterButton/>}
        >
          {
              location?.state?.resetSuccess &&
              <Alert
                  severity={'success'}
                  sx={{mb: 2}}
              >
                {t('authFlow.updateSuccess')}
                <br/>
                {t('authFlow.newPassword')}
              </Alert>
          }
          <Box mb={3}>
            <Typography variant="h5">{t(
                'authFlow.player.login.heading')}</Typography>
          </Box>
          <Stack spacing={2}>
            <Controller
                name={'email'}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('authFlow.fields.errorEmail'),
                  },
                  validate: {
                    invalid: (value) => {
                      return validate(value) || t('validation.emailValidation');
                    },
                  },
                }}
                render={({field}) => {
                  return <TextField
                      autoFocus
                      fullWidth
                      required
                      label={t('authFlow.fields.labelEmail')}
                      type="email"
                      placeholder="jhon@gmail.com"
                      size="medium"
                      error={!!errors['email']}
                      helperText={!!errors['email'] && errors['email'].message}
                      onKeyDown={handleEnterKeySubmit}
                      {...field}
                  />;
                }}
            />
            <Controller
                name={'password'}
                rules={{
                  required: {
                    value: true,
                    message: t('authFlow.fields.passwordError'),
                  },
                }}
                control={control}
                render={({field}) => {
                  return <TextField
                      required
                      fullWidth
                      label={t('authFlow.fields.labelPassword')}
                      size="medium"
                      placeholder={t('authFlow.fields.labelPassword')}
                      type={isPasswordVisible ? 'text' : 'password'}
                      error={!!errors['password']}
                      helperText={errors['password'] &&
                          errors['password'].message}
                      onKeyDown={handleEnterKeySubmit}
                      InputProps={{
                        endAdornment: <InputAdornment
                            position="end"
                            sx={{cursor: 'pointer'}}
                            onClick={handleTogglePasswordVisibility}
                        >
                          {isPasswordVisible ? <VisibilityOff/> : <Visibility/>}
                        </InputAdornment>,
                      }}
                      {...field}
                  />;
                }}
            />

            <Button
                size={'small'}
                variant={'text'}
                sx={{alignSelf: 'flex-start'}}
                onClick={() => navigateTo('/forgot-password')}
            >
              {t('authFlow.forgotText')}
            </Button>
          </Stack>
          <LoadingButton
              loading={isLoading}
              fullWidth
              size={'large'}
              variant="contained"
              sx={{mt: 3, position: 'relative'}}
              onClick={handleLoginSubmit}
          >
            <span>
               {t('buttons.signButton')}
            </span>
          </LoadingButton>
        </LayoutOnboarding>

      </>
  );
};

function RegisterButton() {

  const {t} = useTranslate();
  const navigateTo = useNavigate();

  function handleRegisterButtonClick() {
    navigateTo('/signup')
  }

  return (
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <Typography color={'text.secondary'}>
          {t('page.login.newUser')}
        </Typography>
        <Typography
            color={'primary'}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
            onClick={handleRegisterButtonClick}
        >
          {t('global.buttons.actions.signUp')}
        </Typography>
      </Stack>
  );
}

export default PageLogin;
