import {Box} from '@mui/material';
import {Outlet} from 'react-router-dom';
import LayoutPublicPaymentsHeader from './LayoutPublicPaymentsHeader';
import SiteFooter from '../../components/_Global/Site/SiteFooter';
import {LAYOUT_MAIN__PAGE_PADDING_BOTTOM} from '../../constants/ui';
import ContainerFullWidth
  from '../../components/_Global/Containers/ContainerFullWidth';

function LayoutPublicPayments() {

  return (
      <Box
          sx={{
            height: '100vh',
            overflow: 'hidden',
            width: '100%',
          }}
      >
        <LayoutPublicPaymentsHeader/>
        <Box
            component={'main'}
            sx={{
              width: '100%',
              height: 'calc(100vh - 64px)',
              backgroundColor: '#fafafa',
              overflowY: 'auto',
              paddingTop: '48px',
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center',
            }}
        >
          <ContainerFullWidth>
            <Outlet/>
          </ContainerFullWidth>
          <Box sx={{minHeight: LAYOUT_MAIN__PAGE_PADDING_BOTTOM}}></Box>
          <SiteFooter/>
        </Box>
      </Box>
  );
}

export default LayoutPublicPayments;
