import {useContext} from 'react';
import {ContextClubExperience} from '../../providers/ProviderClubExperience';

function useClubExperienceContext() {

  const clubExperienceContext = useContext(ContextClubExperience);

  let clubDetails = clubExperienceContext?.clubDetails;
  let companyDetails = clubExperienceContext?.companyDetails;
  let clubPolicies = clubExperienceContext?.clubPolicies;
  let fetchClubPolicies = clubExperienceContext?.fetchClubPolicies;
  let clubSports = clubExperienceContext?.clubSports;
  let clubTimings = clubExperienceContext?.clubTimings;

  return {
    clubDetails,
    clubPolicies,
    fetchClubPolicies,
    clubSports,
    clubTimings,
    companyDetails
  };
}

export default useClubExperienceContext;