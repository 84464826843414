import {Box, Stack, useTheme} from '@mui/material';
import {LAYOUT_MAIN__MOBILE_HEADER__HEIGHT} from '../../constants/ui';
import ContainerFullWidth
  from '../../components/_Global/Containers/ContainerFullWidth';
import SiteMobileNav from '../../components/_Global/Site/SiteMobileNav';

function LayoutMainMobileHeader({navItems}) {

  const theme = useTheme();

  return (
      <Box
          sx={{
            height: LAYOUT_MAIN__MOBILE_HEADER__HEIGHT,
            display: 'flex',
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
      >
        <ContainerFullWidth>
          <Box
              sx={{
                height: LAYOUT_MAIN__MOBILE_HEADER__HEIGHT,
                display: 'flex',
                alignItems: 'center',
              }}
          >
            {/*<SiteLogo/>*/}
            <Stack
                ml={'auto'}
                direction={'row'}
                spacing={1}
            >
              <SiteMobileNav navItems={navItems}/>
            </Stack>
          </Box>
        </ContainerFullWidth>
      </Box>
  );
}

export default LayoutMainMobileHeader;