import {Box, Breadcrumbs, Link as LinkMUI, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

function BreadcrumbsStatic({options, variant = 'standard' || 'compact'}) {

  const defaultStyles = {
    textDecoration: 'none',
    fontSize: '20px',
    color: 'text.secondary',
    fontWeight: 500,
  };

  const lastStyles = {
    color: 'text.primary',
    mr: 1,
    lineHeight: 1.5,
  };

  return <Breadcrumbs>
    {
      (options || []).map((option, index, array) => {

        const IS_LAST = index === (array.length - 1);
        const SLOT = option?.slot;

        return (
            <Box
                key={index}
                display={'inline'}
            >
              <LinkMUI
                  component={option?.route ? Link : Typography}
                  to={option?.route}
                  sx={{
                    ...defaultStyles,
                    ...(IS_LAST && lastStyles),
                    display: 'inline',
                    verticalAlign: 'middle',
                    fontSize: 'clamp(1.00rem, calc(0.81rem + 0.95vw), 1.25rem)',
                  }}
              >
                {option.label}
              </LinkMUI>
              {
                  !!SLOT &&
                  <Box sx={{display: 'inline', lineHeight: 2}}>
                    {SLOT}
                  </Box>
              }

            </Box>
        );
      })
    }
  </Breadcrumbs>;
}

export default BreadcrumbsStatic;
