import {useTolgee} from '@tolgee/react';
import useLocale from './useLocale';
import {useDispatch} from 'react-redux';
import {customersUpdateSelf_PATCH} from '../../vendor/redux/actions/player';
import useIsAuthenticated from '../access/useIsAuthenticated';
import {USER_LOGIN} from '../../vendor/redux/constants';

function useSetLanguage(triggerServerUpdate = false) {

  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();
  const tolgee = useTolgee(['language']);
  const {setLocale} = useLocale();

  function setLanguage(key) {
    tolgee.changeLanguage(key);
    localStorage.setItem('locale', JSON.stringify(key));
    setLocale(key);
    if (isAuthenticated && triggerServerUpdate) {
      updateUserLanguagePreference(key);
    }
  }

  function updateUserLanguagePreference(key) {

    const body = {
      language: key,
    };

    dispatch(customersUpdateSelf_PATCH(body, cbSuccess, cbFail));

    function cbSuccess(res) {
      console.log('customersUpdateSelf_PATCH Success', res);
      const userLocal = JSON.parse(localStorage.getItem('user'));
      userLocal.userObj.language = key;
      localStorage.setItem('user',JSON.stringify(userLocal));
      dispatch({type: USER_LOGIN, payload: userLocal});
    }

    function cbFail(e) {
      console.log('customersUpdateSelf_PATCH Fail', e);
    }
  }

  return setLanguage;

}

export default useSetLanguage;