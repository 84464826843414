import {useSnackbar} from 'notistack';
import {IconButton} from '@mui/material';
import {Close} from '@mui/icons-material';

function SnackbarCloseButton({snackbarKey}) {
  const {closeSnackbar} = useSnackbar();
  return (
      <IconButton onClick={() => closeSnackbar(snackbarKey)}>
        <Close sx={{
          fill: "#fff"
        }}/>
      </IconButton>
  );
}

export default SnackbarCloseButton;
