import {useEffect, useState} from 'react';
import DialogLogin from '../../dialogs/OnBoarding/DialogLogin';
import DialogForgotPassword from '../../dialogs/OnBoarding/DialogForgotPassword';
import DialogRegister from '../../dialogs/OnBoarding/DialogRegister';
  
  function SiteOnBoarding() {
  
    const [isOpenLoginDialog, setIsOpenLoginDialog] = useState(true);
    const [isOpenForgotDialog, setIsOpenForgotDialog] = useState(false);
    const [isOpenRegisterDialog, setIsOpenRegisterDialog] = useState(false);
  
    const [willShowForgotPassword, setWillShowForgotPassword] = useState(false);
    const [willShowLoginDialog, setWillShowLoginDialog] = useState(false);
    const [willShowRegisterDialog, setWillShowRegisterDialog] = useState(false);
  
    useEffect(() => {
      if (willShowForgotPassword) {
        setIsOpenLoginDialog(false);
      }
    }, [willShowForgotPassword]);
  
    useEffect(() => {
      if (willShowLoginDialog) {
        setIsOpenForgotDialog(false);
      }
    }, [willShowLoginDialog]);
  
    useEffect(() => {
      if (willShowRegisterDialog) {
        setIsOpenLoginDialog(false);
      }
    }, [willShowRegisterDialog]);

    return (
        <>
          <DialogLogin
              isOpenDialog={isOpenLoginDialog}
              setIsOpenDialog={()=> console.log()}
              willShowForgotPassword={willShowForgotPassword}
              setWillShowForgotPassword={setWillShowForgotPassword}
              willShowRegisterDialog={willShowRegisterDialog}
              setWillShowRegisterDialog={setWillShowRegisterDialog}
              onLoginSuccess={()=>setIsOpenLoginDialog(false)}
              showRegisterDialog={() => {
                setIsOpenRegisterDialog(true);
              }}
              showForgotPasswordDialog={() => {
                setIsOpenForgotDialog(true);
              }}
          />
          <DialogForgotPassword
              isOpenDialog={isOpenForgotDialog}
              setIsOpenDialog={(e) =>{  
                setIsOpenForgotDialog(e);
                setIsOpenLoginDialog(true)
              }}
              setWillShowForgotPassword={setWillShowForgotPassword}
              willShowLoginDialog={willShowLoginDialog}
              setWillShowLoginDialog={setWillShowLoginDialog}
              showLoginDialog={() => {
                setIsOpenLoginDialog(true);
              }}
          />
          <DialogRegister
              isOpenDialog={isOpenRegisterDialog}
              setIsOpenDialog={(e) => {
                setIsOpenRegisterDialog(e)
                setIsOpenLoginDialog(true)
              }}
              setWillShowRegisterDialog={setWillShowRegisterDialog}
              onRegisterSuccess={()=>setIsOpenRegisterDialog(false)}
          />
        </>
    );
  }
  
  export default SiteOnBoarding;
  