import { lazy } from 'react';
import {Route} from 'react-router-dom';
import PageWalletTopupPurchaseStatus from '../pages/Wallet/PageWalletTopupPurchaseStatus';
const PageWalletOfferPurchaseStatus = lazy(() => import('../pages/Wallet/PageWalletOfferPurchaseStatus'));
const PageWalletTransactionDetail = lazy(() => import('../pages/Wallet/PageWalletTransactionDetail'));
const PageWalletList = lazy(() => import('../pages/Wallet/PageWalletList'));
const PageWalletDetail = lazy(() => import('../pages/Wallet/PageWalletDetail'));

const RoutesWallet = (
    <>
      <Route path="/wallet" element={<PageWalletList/>}/>

      <Route path="/wallet/:clubId/:clubName/detail/*"
             element={<PageWalletDetail/>}/>

      <Route path="/wallet/:clubId/:clubName/transaction/detail"
             element={<PageWalletTransactionDetail/>}/>

      <Route path="/wallet/:clubId/offer/payment-status"
             element={<PageWalletOfferPurchaseStatus experience={'player-experience'}/>}/>

      <Route path=":clubId/wallet/topup/payment-status"
             element={<PageWalletTopupPurchaseStatus experience={'player-experience'}/>}/>

    </>
);

export default RoutesWallet;
