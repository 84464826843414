import {getIsProduction} from './environments';

export function suppressConsoleLog() {

  const isProduction = getIsProduction();

  if (isProduction) {
    console.log = function() {
    };
    // console.info = function () {};
    // console.warn = function () {};
    // console.error = function () {};
  }
}