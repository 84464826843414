import { lazy } from 'react';
import {Route} from 'react-router-dom';

const PageProfileChangePassword = lazy(() => import('../pages/Profile/PageProfileChangePassword'));

const RoutesSettingsPassword = (
    <>
      <Route path={`/settings/change-password/*`}
             element={<PageProfileChangePassword/>}/>
    </>
);

export default RoutesSettingsPassword;
