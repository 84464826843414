import {Outlet} from 'react-router-dom';
import useIsAuthenticated from '../hooks/access/useIsAuthenticated';
import useUser from '../hooks/access/useUser';
import SiteOnBoarding from '../components/Onboarding/SiteOnboarding';
import { Box } from '@mui/material';

function AccessAuthenticated() {

  const USER = useUser();
  const IS_AUTHENTICATED = useIsAuthenticated();

  return  <>
  {IS_AUTHENTICATED && USER ? (
    // Render the protected route content
    <Outlet />
  ) : (
    // If not authenticated, show login popup
    <Box sx={{height: '100vh'}}>
      <SiteOnBoarding />
    </Box>
  )}
</>
}

export default AccessAuthenticated;
