import {createContext, useEffect, useState} from 'react';

export const OnboardingContext = createContext();

function ProviderOnboarding({children}) {

  const [isOpenLoginDialog, setIsOpenLoginDialog] = useState(false);
  const [isOpenForgotDialog, setIsOpenForgotDialog] = useState(false);
  const [isOpenRegisterDialog, setIsOpenRegisterDialog] = useState(false);

  const [willShowForgotPassword, setWillShowForgotPassword] = useState(false);
  const [willShowLoginDialog, setWillShowLoginDialog] = useState(false);
  const [willShowRegisterDialog, setWillShowRegisterDialog] = useState(false);
  
  const [preFillEmail,setPreFillEmail] = useState('');


  useEffect(() => {
    if (willShowForgotPassword) {
      setIsOpenLoginDialog(false);
    }
  }, [willShowForgotPassword]);

  useEffect(() => {
    if (willShowLoginDialog) {
      setIsOpenForgotDialog(false);
    }
  }, [willShowLoginDialog]);

  useEffect(() => {
    if (willShowRegisterDialog) {
      setIsOpenLoginDialog(false);
    }
  }, [willShowRegisterDialog]);
  
  useEffect(() => {
    if (!isOpenLoginDialog) {
      setPreFillEmail('');
    }
  }, [isOpenLoginDialog]);

  return (
      <OnboardingContext.Provider
          value={{
            isOpenLoginDialog,
            setIsOpenLoginDialog,
            isOpenForgotDialog,
            setIsOpenForgotDialog,
            isOpenRegisterDialog,
            setIsOpenRegisterDialog,
            willShowForgotPassword,
            setWillShowForgotPassword,
            willShowLoginDialog,
            setWillShowLoginDialog,
            willShowRegisterDialog,
            setWillShowRegisterDialog,
            preFillEmail,
            setPreFillEmail
          }}>
        {children}
      </OnboardingContext.Provider>
  );
}

export default ProviderOnboarding;