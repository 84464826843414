import {Box} from '@mui/material';
import LayoutMainHeaderBottom from './LayoutMainHeaderBottom';
import LayoutMainHeaderTop from './LayoutMainHeaderTop';
import useIsAuthenticated from '../../hooks/access/useIsAuthenticated';
import LayoutMainPublicHeader from './LayoutMainPublicHeader';

function LayoutMainHeader({navItems}) {

  const isAuthenticated = useIsAuthenticated();

  return (
      <Box
          component={'header'}
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
          }}
      >
        {
          isAuthenticated &&<LayoutMainHeaderTop/>
        }
        {
          isAuthenticated &&
          <LayoutMainHeaderBottom navItems={navItems}/>
        }

      </Box>
  );
}

export default LayoutMainHeader;
