import {statusError, statusLoading, statusSuccess} from './status';
import {
  CLUBS_AMENITIES_LIST_FAILURE,
  CLUBS_AMENITIES_LIST_SUCCESS,
  CLUBS_AMENITIES_LOADING,
  CUSTOMER_BOOKINGS_LIST_GET_FAILURE,
  CUSTOMER_BOOKINGS_LIST_GET_LOADING,
  CUSTOMER_BOOKINGS_LIST_GET_SUCCESS,
  GET_CLUB_TIMING_FAILURE,
  GET_CLUB_TIMING_LOADING,
  GET_CLUB_TIMING_SUCCESS,
  GET_COURT_FAILURE,
  GET_COURT_LOADING,
  GET_COURT_SUCCESS,
  GET_SPORTS_FAILURE,
  GET_SPORTS_LOADING,
  GET_SPORTS_SUCCESS,
} from '../constants';
import axios from 'axios';

export const customersClubsAminitesList_GET = (clubId, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(CLUBS_AMENITIES_LOADING));
      const response = await axios.get(
          `customers/clubs/aminites/list?clubId=${clubId}`);
      dispatch(statusSuccess(CLUBS_AMENITIES_LIST_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      dispatch(statusError(CLUBS_AMENITIES_LIST_FAILURE, e));
      cbFail(e);
    }
  };
};

export const customersClubsTimingsList_GET = (clubId, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_CLUB_TIMING_LOADING));
      const url = `customers/clubs/timings/list?clubId=${clubId}`;
      const response = await axios.get(url);
      dispatch(statusSuccess(GET_CLUB_TIMING_SUCCESS, response.data.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(GET_CLUB_TIMING_FAILURE, e));
    }
  };
};

export const courtsListForPlayer_GET = (
    clubId, limit, pageNo, sport, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_COURT_LOADING));
      const sortBy = 'name';
      const orderBy = 'ASC';
      const response = await axios.get(
          `customers/courts/list?sortBy=${sortBy}&orderBy=${orderBy}&clubId=${clubId}&version=2&limit=${limit}&page=${pageNo}&sport=${sport}`);
      dispatch(statusSuccess(GET_COURT_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(GET_COURT_FAILURE, e));
    }
  };
};

export const customersSportsList_GET = (id, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      const url = id ?
          `customers/sports/list/club?clubId=${id}` :
          `sports/list?limit=1000`;
      dispatch(statusLoading(GET_SPORTS_LOADING));
      const response = await axios.get(url);
      dispatch(statusSuccess(GET_SPORTS_SUCCESS, response.data.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(GET_SPORTS_FAILURE, e));
    }
  };
};

export const customersSportsClubList_GET = (clubId, cbSuccess, cbFail) => {
  return async (dispatch) => {
    try {
      dispatch(statusLoading(GET_SPORTS_LOADING));
      const response = await axios.get(`customers/sports/club/list?clubId=${clubId}`);
      dispatch(statusSuccess(GET_SPORTS_SUCCESS, response.data.data));
      cbSuccess(response);
    } catch (e) {
      cbFail(e);
      dispatch(statusError(GET_SPORTS_FAILURE, e));
    }
  };
};

export const customersSchedulerSelf_GET = (
    clubId, date, cbSuccess, cbFailure) => {
  return async (dispatch) => {
    try {

      dispatch(statusLoading(CUSTOMER_BOOKINGS_LIST_GET_LOADING));
      const response = await axios.get(
          `customers/scheduler/self?&clubId=${clubId}&date=${date}`);
      dispatch(
          statusSuccess(CUSTOMER_BOOKINGS_LIST_GET_SUCCESS, response.data));
      cbSuccess(response);
    } catch (e) {
      cbFailure(e);
      dispatch(statusError(CUSTOMER_BOOKINGS_LIST_GET_FAILURE, e));
    }
  };
};
