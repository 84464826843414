import {Box} from '@mui/material';

function ContainerFixed({maxWidth = '1248px', justify = 'center', children}) {
  return <Box
      sx={{
        ...(justify === 'center') && {
          margin: '0 auto',
        },
        maxWidth: maxWidth,
        width: '100%',
        paddingX: 3,
      }}
  >
    {children}
  </Box>;
}

export default ContainerFixed;
