import {
  Alert,
  AlertTitle,
  Box,
  Button,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import {useNavigate, useParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {Controller, useForm} from 'react-hook-form';
import LayoutOnboarding from '../../layouts/LayoutOnboarding/LayoutOnboarding';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {useRef, useState} from 'react';
import {useTranslate} from '@tolgee/react';

const PageResetPassword = () => {

  const {t} = useTranslate();
  const {enqueueSnackbar} = useSnackbar();
  const navigateTo = useNavigate();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isResetSuccess, setIsResetSuccess] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setError,
    formState: {errors, isSubmitting},
  } = useForm();

  const {token} = useParams();

  const passwordRef = useRef();
  passwordRef.current = watch('password', '');

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((cur) => !cur);
  };

  const handlePasswordUpdateSubmit = () => {
    handleSubmit(
        async ({password}) => {

          const resetAPIPath = 'customers/change-password';
          try {
            const response = await axios.post(resetAPIPath, {password, token});
            enqueueSnackbar(t('global.alerts.resetPassword.success'),
                {variant: 'success'});

            setIsResetSuccess(true);

          } catch ({response}) {

            console.log('response', response);

            const errorResponse = response.data.data;

            if (errorResponse === 'backendErrors.tokenExpried') {
              enqueueSnackbar(t('alerts.globalErrorMessage'),
                  {variant: 'error'});
              setError('token', {
                type: 'token invalid',
                message: t('authFlow.resetPassword.expireMesssage'),
              });
            } else {
              enqueueSnackbar(t(errorResponse), {variant: 'error'});
              setError('password', {message: errorResponse});
              setError('confirm-password', {message: errorResponse});
            }
          }
        },
        () => {
          enqueueSnackbar(t('alerts.globalErrorMessage'), {variant: 'error'});
        },
    )();
  };

  const handleEnterKeySubmit = (e) => {
    e.key === 'Enter' && handlePasswordUpdateSubmit();
  };

  return (
      <LayoutOnboarding width={'632px'} isLoading={isSubmitting}>
        <Stack>
          <Box mb={3}>
            <Typography sx={{mb: 1}} variant="h5">
              {t('authFlow.resetPassword.heading')}
            </Typography>
            <Typography variant="body2" color={'text.secondary'}>
              {t('authFlow.resetPassword.subHeading')}
            </Typography>
          </Box>
          {
              isResetSuccess &&
              <Alert severity={'success'}>
                <AlertTitle>{t(
                    'global.alerts.passwordReset.success.title')}</AlertTitle>
                {t('global.alerts.passwordReset.success.description')}
              </Alert>
          }
          {
              !isResetSuccess &&
              <>
                <Controller
                    name={'password'}
                    control={control}
                    defaultValue={''}
                    rules={{
                      required: {
                        value: true,
                        message: t('authFlow.fields.passwordError'),
                      },
                    }}
                    render={({field}) => {
                      return (
                          <TextField
                              disabled={errors['token']}
                              autoFocus
                              autoComplete={'new-password'}
                              fullWidth
                              required
                              size="medium"
                              type={isPasswordVisible ? 'text' : 'password'}
                              label={t('authFlow.fields.labelNewPassword')}
                              placeholder={t(
                                  'global.fields.placeholder.newPassword')}
                              error={!!errors['password']}
                              helperText={!!errors['password'] &&
                                  errors['password'].message}
                              InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: <InputAdornment
                                    position="end"
                                    sx={{cursor: 'pointer'}}
                                    onClick={handleTogglePasswordVisibility}
                                >
                                  {isPasswordVisible ?
                                      <VisibilityOff/> :
                                      <Visibility/>}
                                </InputAdornment>,
                              }}
                              onKeyDown={handleEnterKeySubmit}
                              sx={{
                                mb: 2,
                              }}
                              {...field}
                          />
                      );
                    }}
                />

                <Controller
                    name={'confirm-password'}
                    control={control}
                    defaultValue={''}
                    rules={{
                      required: {
                        value: true,
                        message: t('authFlow.fields.confirmPasswordError'),
                      },
                      validate: (value) => {
                        return value === passwordRef.current ||
                            t('authFlow.fields.validatePasowrd');
                      },
                    }}
                    render={({field}) => {
                      return (
                          <TextField
                              disabled={errors['token']}
                              required
                              fullWidth
                              label={t(
                                  'authFlow.fields.labelConfirmPassword')}
                              placeholder={t(
                                  'authFlow.fields.labelConfirmPassword')}
                              size="medium"
                              type={isPasswordVisible ? 'text' : 'password'}
                              error={!!errors['confirm-password']}
                              helperText={errors['confirm-password'] &&
                                  errors['confirm-password'].message}
                              InputProps={{
                                autoComplete: 'new-password',
                              }}
                              onKeyDown={handleEnterKeySubmit}
                              {...field}
                          />);
                    }}
                />

                {
                    errors['token'] &&
                    <Alert sx={{mt: 2}}
                           severity={'error'}>
                      {t('authFlow.resetPassword.expireMesssage')}
                      <Link
                          sx={{
                            cursor: 'pointer',
                            color: 'rgb(95, 33, 32)',
                            textDecorationColor: 'inherit',
                            fontWeight: 'bold',
                            alignSelf: 'flex-start',
                            ml: 1,
                          }}
                          onClick={() => navigateTo('/forgot-password')}
                      >
                        {t('authFlow.resetPassword.resendLink')}
                      </Link>
                    </Alert>
                }

                <Stack direction={'row'} columnGap={1} mt={3}>
                  <Button
                      fullWidth
                      size={'large'}
                      variant="contained"
                      onClick={handlePasswordUpdateSubmit}
                      disabled={isSubmitting || errors['password'] ||
                          errors['confirm-password'] ||
                          errors['token'] ||
                          errors['match-error']}
                  >
                    {t('buttons.confirmButton')}
                  </Button>
                </Stack>
              </>
          }

        </Stack>
      </LayoutOnboarding>
  );
};

export default PageResetPassword;
