import {forwardRef} from 'react';
import {Fade, Grow, Slide, Zoom} from '@mui/material';

export const TransitionZoom = forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

export const TransitionSlide = forwardRef(function Transition(props, ref) {
  return <Slide direction={'up'} ref={ref} {...props} />;
});

export const TransitionGrow = forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

export const TransitionFade = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});
